import React, { useState, useEffect } from "react"
import { useStore, useStoreValue } from "react-context-hook"
import { jsPDF } from "jspdf"
import { renderToString } from "react-dom/server"
import { useLocation } from "react-router-dom"

const makeHTML = (
  sizeStyle,
  nonSurvey,
  nonConformantQuestions,
  answers,
  assessment,
  img1
) => {
  const listOfSurveyTitles = nonSurvey?.map((id) => {
    return answers[id].schema.title
  })
  let i = 0
  return (
    nonSurvey &&
    nonConformantQuestions && (
      <div
        style={{
          fontFamily: "Arial, Helvetica, sans-serif",
          maxWidth: "700px",
          margin: "auto",
        }}
        className="py-0 "
      >
        <div style={sizeStyle.logo}>
          <img style={sizeStyle.logoImg} src={img1} />
        </div>
        <div
          style={sizeStyle.mainTitleDiv}
          className="flex flex-col justify-center items-center py-0 "
        >
          <p className=" my-0 p-0">{assessment.subject.name}</p>
          <p className=" my-0 p-0">Non Conformances</p>
        </div>
        <div style={sizeStyle.bodyDiv} className="py-2 px-4">
          {nonSurvey.map((survey, index) => {
            i = index
            return (
              <div className="mb-10" key={survey}>
                <p
                  className="text-center my-0 p-0"
                  style={sizeStyle.sectionTitleFont}
                >
                  {listOfSurveyTitles[i]}
                </p>{" "}
                <p style={sizeStyle.date} className="text-center">
                  {" "}
                  {answers[survey].sign_off_date}
                </p>
                {Object.keys(nonConformantQuestions[survey]).map((key) => {
                  const title = key.split("%&")[0]
                  return (
                    <div className="p-1" key={key}>
                      <p style={sizeStyle.sectionTitleFont}>{title} :</p>
                      {nonConformantQuestions[survey][key].length > 0 ? (
                        nonConformantQuestions[survey][key].map((question) => {
                          return (
                            <div
                              key={question.id}
                              style={sizeStyle.sectionBody}
                              className="p-1"
                            >
                              <p style={sizeStyle.questionFont}>
                                {question.question}
                              </p>
                              <p style={sizeStyle.answerFont} className="pl-1">
                                Answer : {question.answer}
                              </p>
                              <p style={sizeStyle.answerFont} className="pl-1">
                                Comment : {question.actions.comment}
                              </p>
                            </div>
                          )
                        })
                      ) : (
                        <p style={sizeStyle.answerFont}>
                          No non conformances for this survey
                        </p>
                      )}
                    </div>
                  )
                })}
                <div className="px-1" style={sizeStyle.sigContainer}>
                  <div>
                    <p style={sizeStyle.sigOwner}>Farmer</p>
                    <p style={sizeStyle.sigFont}>
                      Name: {` ${answers[survey].schema.farmer_name}`}
                    </p>
                    <p style={sizeStyle.sigFont}> Signature:</p>
                    <img
                      style={sizeStyle.sigImage}
                      src={answers[survey].schema?.farmer_signature}
                    />
                  </div>
                  <div>
                    <p style={sizeStyle.sigOwner}>Assessor</p>
                    <p style={sizeStyle.sigFont}>
                      Name:
                      {` ${answers[survey]?.schema.completed_by.firstname} ${answers[survey]?.schema.completed_by.lastname}`}
                    </p>
                    <p style={sizeStyle.sigFont}> Signature: </p>
                    <img
                      style={sizeStyle.sigImage}
                      src={answers[survey].schema?.assessor_signature}
                    />
                    {/* {img1} */}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  )
}

export const HtmlToPDF = (props) => {
  const branding = useStoreValue("branding", null)
  const [assessments, setAssessments] = useStore("assessments", null)
  const [assessment, setAssessment] = useStore(
    "assessment",
    assessments.find((ass) => ass.unique_id === props.match.params.assessmentId)
  )

  const [logo, setLogo] = useState(null)
  const [nonConformantQuestions, setNonConformantQuestions] = useState([])
  const [listOfNonConSurveys, setListOfNonConSurveys] = useState(null)

  function useQuery() {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
  }
  const query = useQuery()

  const createPDF = async () => {
    if (listOfNonConSurveys && nonConformantQuestions && branding) {
      const img = localStorage.getItem("brandImg")
      const doc = new jsPDF("p", "mm", "a4")
      const source = renderToString(
        makeHTML(
          sizePDFStyle,
          listOfNonConSurveys,
          nonConformantQuestions,
          JSON.parse(localStorage.getItem("answers")),
          assessment,
          img
        )
      )

      doc.html(source, {
        maxWidth: 1,
        autoPaging: "text",
        callback: function (doc) {
          doc.save("a4.pdf")
        },
        x: 0,
        y: 0,
        margin: [15, 0, 15, 0],
      })
    }
  }

  const getCurrentSurveysFromAnswers = () => {
    const localStorageAnswers = JSON.parse(localStorage.getItem("answers"))
    const currentSurveys = []

    query
      .get("notCompletedSurveysIds")
      .split(",")
      ?.forEach((id) => {
        currentSurveys.push(localStorageAnswers[id])
      })
    return currentSurveys
  }

  const findAllNonConformants = (currentSurveys) => {
    const all_non_conformants = {}

    currentSurveys
      .filter((u) => u !== undefined)
      .forEach((survey) => {
        survey?.schema?.sections.forEach((section, idx) => {
          const non_conformants = []
          section.questions.forEach((q) => {
            if (
              // q.conformance &&
              // JSON.parse(q.conformance.conformant) === false
              q.actions
            ) {
              non_conformants.push(q)
            }
            if (non_conformants.length >= 0) {
              all_non_conformants[survey.unique_id] = {
                ...all_non_conformants[survey.unique_id],
                [section.title + "%&" + idx]: non_conformants,
              }
            }
          })
        })
      })
    return all_non_conformants
  }

  useEffect(() => {
    const currentSurveys = getCurrentSurveysFromAnswers()
    const all_non_conformants = findAllNonConformants(currentSurveys)
    const _listOfSurveys = Object.keys(all_non_conformants)
    setListOfNonConSurveys(_listOfSurveys)
    setNonConformantQuestions(all_non_conformants)
  }, [])

  useEffect(() => {
    if (listOfNonConSurveys && nonConformantQuestions && branding) {
      createPDF()
    }
  }, [listOfNonConSurveys, nonConformantQuestions, branding, logo])

  // STYLES

  const sizePDFStyle = {
    logo: {
      width: "50px",
      height: "50px",
      position: "absolute",
      left: "140px",
    },
    outerDiv: { height: "260mm", width: "210mm" },
    mainTitleDiv: {
      width: "210px",
      maxWidth: "210px",
      fontSize: "8px",
      paddingTop: "20px",
      marginTop: "0px",
    },
    bodyDiv: {
      width: "210px",
      maxWidth: "210px",
      fontSize: "6px",
      fontVariantLigatures: "no-common-ligatures",
    },
    sectionBody: {},
    sectionTitleFont: {},
    date: { fontSize: "4px" },
    questionFont: { fontSize: "4px" },
    answerFont: { fontSize: "4px" },
    sigContainer: {},
    sigImage: { height: "20px", marginTop: "8px" },
    sigOwner: { fontSize: "6px", paddingBottom: "1px" },
    sigFont: { fontSize: "4px" },
  }

  const renderSizeStyle = {
    logo: {
      width: "100%",
      height: "150px",
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
    },
    logoImg: {
      width: "250px",
      // marginRight: "10%",
    },
    outerDiv: { width: "100%", overflow: "hidden" },
    mainTitleDiv: {
      fontSize: "30px",
      padding: "8px 8px 0px 8px",
      paddingTop: "0",
      // paddingBottom: "30px",
    },
    bodyDiv: {
      padding: "8px",
      maxWidth: "600px",
      margin: "auto",
      paddingTop: "20px",
    },
    sectionBody: { padding: "16px 0" },
    sectionTitleFont: {
      fontSize: "20px",
      fontWeight: "600",
      marginTop: "30px",
    },
    date: {},
    questionFont: { fontSize: "16px" },
    answerFont: { paddingLeft: "40px", paddingTop: "4px" },
    sigContainer: { padding: "20px 0 " },
    sigImage: { height: "80px", marginTop: "16px" },
    sigOwner: {},
    sigFont: {},
  }

  return makeHTML(
    renderSizeStyle,
    listOfNonConSurveys,
    nonConformantQuestions,
    JSON.parse(localStorage.getItem("answers")),
    assessment,
    localStorage.getItem("brandImg")
  )
}

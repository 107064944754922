import React, { useEffect, useRef, useState } from "react"
import { useStore } from "react-context-hook"
import {
  evaluateQuestionDisplayConditions,
  resetNotDisplayedQuestions,
} from "../../utils"
import { Header } from "../components/header"
import { NavBar } from "../components/navbar"
import { Question } from "../components/questions/question"
import { useHistory, useParams } from "react-router-dom"
import TranslationWrapper from "../utils/translationWrapper"
import { UnsavedAnswerBanner } from "../components/banner/unsavedAnswerBanner"
import HasAnswerChangedModal from "../components/modal/hasAnswerChangedModal"

export const Section = (props) => {
  const [captureGroups, setCaptureGroups] = useStore("captureGroups", null)
  const [captureGroup, setCaptureGroup] = useStore(
    "captureGroup",
    captureGroups?.find(
      (cg) => cg.unique_id === props.match.params.captureGroupId
    )
  )
  const [assessments, setAssessments] = useStore("assessments", null)
  const [assessment, setAssessment] = useStore(
    "assessment",
    assessments?.find(
      (ass) => ass.unique_id === props.match.params.assessmentId
    )
  )

  const [surveys, setSurveys] = useStore("surveys", null)
  const [survey, setSurvey] = useStore("survey", null)
  const [section, setSection] = useState([])
  const [questions, setQuestions] = useState([])
  const [answers, setAnswers] = useStore("answers", null)
  const [pendingQuestionCount, setPendingQuestionCount] = useState(null)
  const [showSubmitButton, setShowSubmitButton] = useState(false)
  const [showAnswerChangeModal, setShowAnswerChangeModal] = useState(false)
  const [linkToURL, setLinkToUrl] = useState(null)

  const [hasAnswersChanged, setHasAnswersChanged] = useStore(
    "hasAnswersChanged"
    // false
  )

  const history = useHistory()

  useEffect(() => {
    const sectionId = props.match.params.sectionId.split("?").at(0)
    const index = parseInt(sectionId) - 1

    if (surveys && assessment) {
      const thisSurvey = surveys.filter(
        (survey) => survey.unique_id === props.match.params.surveyId
      )[0]
      const filteredSurvey = filterSurveyActions(assessment, thisSurvey)
      const sections = filteredSurvey.schema.sections
      const section = sections[index]

      // const sections = thisSurvey.schema.sections
      // const section = sections[index]

      setSurvey(filteredSurvey)
      setSection(section)
      setQuestions(section.questions)
    }
  }, [surveys, assessment])

  useEffect(() => {
    let pendingCount = getPendingQuestionsCount()

    if (pendingCount === 0 && questions.length > 0) {
      setShowSubmitButton(true)
    } else {
      setShowSubmitButton(false)
    }
    setPendingQuestionCount(pendingCount)
  }, [questions, answers])

  const filterSurveyActions = (thisAssessment, surveys) => {
    const species = []
    // collect relevant farm species
    thisAssessment.subject.species.forEach((spec) => {
      if (!species.includes(spec.species)) {
        species.push(spec.species.toLowerCase())
      }
    })
    // removes not relevant standards
    const temp = { ...surveys }
    temp?.schema?.sections.forEach((section) => {
      section?.questions.forEach((question) => {
        const newStandards = question?.standards?.filter((standard) => {
          return standard.species?.some((s) => {
            return species.includes(s.toLowerCase())
          })
        })
        if (newStandards && newStandards.length > 0) {
          question.standards = newStandards
        } else {
          delete question.standards
        }
      })
    })
    return temp
  }

  const getPendingQuestionsCount = () => {
    const sectionId = props.match.params.sectionId.split("?").at(0)
    const index = parseInt(sectionId) - 1
    if (answers && questions.length > 0) {
      let answeredCount = 0
      if (answers[props.match.params.surveyId]) {
        answers[props.match.params.surveyId].schema.sections[index].questions
          .filter((q) => q.type !== "blank")
          .forEach((q) => {
            if (
              q.hasOwnProperty("answer") ||
              !q.required ||
              !evaluateQuestionDisplayConditions(
                q,
                answers,
                survey,
                assessment?.subject.species || captureGroup?.subject.species,
                index
              )
            ) {
              answeredCount++
            }
          })
      }
      return questions.filter((q) => q.type !== "blank").length - answeredCount
    } else {
      return questions.filter((q) => {
        return (
          q.type !== "blank" &&
          evaluateQuestionDisplayConditions(
            q,
            answers,
            survey,
            assessment?.subject.species || captureGroup?.subject.species,
            index
          )
        )
      }).length
    }
  }

  const getPendingQuestionsCountForAll = (surveyArr) => {
    let completedArr = []

    if (answers && surveyArr && surveyArr.length > 0) {
      for (let i = 0; i < surveyArr.length; i++) {
        const currentQuestions = surveyArr[i].questions

        const sectionId = props.match.params.sectionId.split("?").at(0)
        const index = parseInt(sectionId) - 1

        let answeredCount = 0
        if (answers[props.match.params.surveyId]) {
          currentQuestions
            .filter((q) => q.type !== "blank")
            .forEach((q) => {
              if (
                q.hasOwnProperty("answer") ||
                !q.required ||
                !evaluateQuestionDisplayConditions(
                  q,
                  answers,
                  survey,
                  assessment?.subject.species || captureGroup?.subject.species,
                  i
                )
              ) {
                answeredCount++
              }
            })
        }

        currentQuestions.filter((q) => q.type !== "blank").length -
          answeredCount ===
        0
          ? completedArr.push(true)
          : completedArr.push(false)
      }
    }
    return completedArr
  }

  const getCorrectUrl = () => {
    const path = props.match.path

    const surveyStatus = getPendingQuestionsCountForAll(
      answers[props.match.params.surveyId]?.schema?.sections
    )
    if (path.includes("/capture-group")) {
      if (surveyStatus.includes(false)) {
        return `/capture-group/${props.match.params.captureGroupId}/survey/${props.match.params.surveyId}`
      } else {
        return `/capture-group/${props.match.params.captureGroupId}`
      }
    }

    if (path.includes("/assessment")) {
      if (surveyStatus.includes(false)) {
        return `/assessment/${props.match.params.assessmentId}/survey/${props.match.params.surveyId}`
      } else {
        return `/assessment/${props.match.params.assessmentId}`
      }
    }
  }

  return (
    <>
      {/* <Header /> */}
      <NavBar
        // hasAnswersChanged={hasAnswersChanged}
        text={
          pendingQuestionCount === 0
            ? `All questions answered`
            : `${pendingQuestionCount} questions pending`
        }
        iconLeft="back"
        route={`/${survey?.capturing ? "capture-group" : "assessment"}/${
          props.match.params.assessmentId || props.match.params.captureGroupId
        }/survey/${props.match.params.surveyId}`}
      />
      <UnsavedAnswerBanner />
      <div className="mt-2 mb-2 p-4 text-gray-500 text-center">
        <div className="font-semibold text-xl text-gray-800">
          <TranslationWrapper translations={survey?.survey_schema_translations}>
            {section.title}
          </TranslationWrapper>
        </div>
        <div className="text-sm">
          Answer all of the questions in this survey section.
        </div>
      </div>
      <div className="mx-auto border-t-2 border-gray-500 md:border-t-0 md:w-1/2 lg:w-1/4 md:pt-5">
        {questions.length > 0 &&
          questions.map((question, index) => {
            const displayQuestionBool =
              evaluateQuestionDisplayConditions(
                question,
                answers,
                survey,
                assessment?.subject.species || captureGroup?.subject.species,
                parseInt(props.match.params.sectionId.split("?").at(0)) - 1
              ) && question.type !== "blank"

            if (!displayQuestionBool) {
              resetNotDisplayedQuestions(
                question,
                survey,
                parseInt(props.match.params.sectionId.split("?").at(0)) - 1,
                answers,
                setAnswers
              )
            }
            const question_number =
              questions
                .filter((q) => {
                  return displayQuestionBool
                })
                .findIndex((q) => q.id === question.id) + 1

            return (
              <Question
                key={question.id}
                index={index}
                question={question}
                questions={questions}
                question_number={question_number}
                params={props.match.params}
              />
            )
          })}

        {showSubmitButton && (
          <button
            className="rounded my-2 p-2 mb-8 w-full bg-green-600 text-white"
            onClick={() => {
              if (hasAnswersChanged) {
                setLinkToUrl(() => getCorrectUrl())
                setShowAnswerChangeModal(true)
              } else {
                let url = getCorrectUrl()
                history.push(url)
              }
            }}
          >
            Continue
          </button>
        )}
        {showAnswerChangeModal && linkToURL && (
          <HasAnswerChangedModal
            pushTo={linkToURL}
            setShowModal={setShowAnswerChangeModal}
          />
        )}
      </div>
    </>
  )
}

import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router"
import { useStore } from "react-context-hook"
import { FaExclamation } from "react-icons/fa"
import { RiCloseCircleFill } from "react-icons/ri"
import { MultiSelect } from "react-multi-select-component"
import { Header } from "../components/header"
import { NavBar } from "../components/navbar"
//import { getSpeciesObject, codeFromSpeciesObject } from '../../utils/species'
import "./custom.css"
import AddLinkedHolding from "./addLinkedHolding"
import SlapMark from "./slapmark"

const getSurveySpeciesDeps = (survey) => {
  const deps = []
  survey.schema.sections.forEach((section) => {
    section.questions.forEach((q) => {
      if (
        !deps.includes("standards") &&
        q.display_conditions?.includes("[standards]")
      ) {
        deps.push("standards")
      }
      if (
        !deps.includes("siteTypes") &&
        q.display_conditions?.includes("[siteTypes]")
      ) {
        deps.push("siteTypes")
      }
    })
  })
  return deps
}

const filterAllByDeps = (speciesObject, property, speciesConfig) => {
  return Object.keys(speciesConfig[property]).some((s) => {
    return speciesConfig[property][s].dependencies.find((dep) => {
      let matches = true
      if (dep.categories && !dep.categories.includes(speciesObject.category))
        return false
      if (dep.species && !dep.species.includes(speciesObject.species))
        return false
      if (dep.products && !dep.products.includes(speciesObject.product))
        return false
      if (dep.standards) {
        if (speciesObject.standards && speciesObject.standards.length > 0) {
          matches = speciesObject.standards.some((s) =>
            dep.standards.includes(s)
          )
        } else {
          return false
        }
      }
      if (dep.siteTypes) {
        if (speciesObject.standards && speciesObject.standards.length > 0) {
          matches = speciesObject.siteTypes.some((s) =>
            dep.siteTypes.includes(s)
          )
        } else {
          return false
        }
      }
      return matches
    })
  })
}

export const FarmProfileVerify = (props) => {
  const history = useHistory()
  const [account, setAccount] = useStore("account", null)
  const [assessments, setAssessments] = useStore("assessments", null)
  const [assessment, setAssessment] = useState(null)
  const [surveys, setSurveys] = useStore("surveys", null)
  const [thisSurveys, setThisSurveys] = useState(null)
  const [surveysDeps, setSurveysDeps] = useState(null)
  const [surveyDepsError, setSurveyDepsError] = useState(null)
  const [speciesConfig, setSpeciesConfig] = useStore("speciesConfig", null)
  const [newAssuranceFormOpen, setNewAssuranceFormOpen] = useState(false)
  const [newAssuranceSpeciesIndex, setNewAssuranceSpeciesIndex] = useState(null)
  const [confirmPopup, setConfirmPopup] = useState(null)
  const [linkedHolding, setLinkedHolding] = useState({})

  const [slapMarksMap, setSlapMarksMap] = useState({})
  const [hasPork, setHasPork] = useState(false)

  const params = useParams()

  useEffect(() => {
    checkForExistingLinkedHoldings()
  }, [])

  useEffect(() => {
    if (assessments && surveys) {
      const thisAssessment = assessments.filter(
        (assmnt) => assmnt.unique_id == props.match.params.assessmentId
      )[0]
      if (thisAssessment) {
        setAssessment(thisAssessment)
        const assessmentSurveysIDs = thisAssessment.surveys.map(
          (s) => s.unique_id
        )
        const assessmentSurveys = surveys.filter((s) =>
          assessmentSurveysIDs.includes(s.unique_id)
        )
        setThisSurveys(assessmentSurveys)
        setSurveysDeps([
          ...new Set( // remove duplicates
            assessmentSurveys
              .map((survey) => getSurveySpeciesDeps(survey))
              .flat()
          ),
        ])
      }
    }
  }, [assessments, surveys])

  const checkForExistingLinkedHoldings = () => {
    try {
      const { linkedHoldings: _LH } = JSON.parse(
        localStorage.getItem(`profile_verified_${params.assessmentId}`)
      )
      if (_LH?.length) {
        const temp = {}

        _LH.forEach((LH, index) => {
          temp[index] = LH
        })
        setLinkedHolding({ ...temp })
      }
    } catch (error) {}
  }

  const hasPorkSpeciesHelper = (value) => {
    return value.species?.some((spec) => {
      return spec.category.toLowerCase() === "pork"
    })
  }

  useEffect(() => {
    if (assessment) {
      const _hasPorkSpecies = hasPorkSpeciesHelper(assessment.subject)
      _hasPorkSpecies ? setHasPork(true) : setHasPork(false)
      debugger
      //JSON.parse(JSON.stringify(assessment))
      setSlapMarksMap({ 0: ["BCRE7654"] })
      assessment.subject?.linkedHoldings?.forEach((LH, idx) => {
        const slapMarkObj = Object.assign({}, LH?.slapMarks)
        setSlapMarksMap((curr) => ({ ...curr, [idx]: slapMarkObj }))
      })
    }
  }, [assessment])

  const filterByDeps = (speciesObject, dependencies) => {
    return dependencies.find((dep) => {
      let matches = true
      if (dep.categories && !dep.categories.includes(speciesObject.category))
        return false
      if (dep.species && !dep.species.includes(speciesObject.species))
        return false
      if (dep.products && !dep.products.includes(speciesObject.product))
        return false
      if (dep.standards) {
        if (speciesObject.standards && speciesObject.standards.length > 0) {
          matches = speciesObject.standards.some((s) =>
            dep.standards.includes(s)
          )
        } else {
          return false
        }
      }
      if (dep.siteTypes) {
        if (speciesObject.standards && speciesObject.standards.length > 0) {
          matches = speciesObject.siteTypes.some((s) =>
            dep.siteTypes.includes(s)
          )
        } else {
          return false
        }
      }
      return matches
    })
  }

  const speciesSelect = (
    assessmentSpeciesID,
    label,
    category,
    speciesObject
  ) => {
    let options = []

    const categoryValue = speciesObject[category]

    switch (category) {
      case "category":
        options = speciesConfig.categories
        break
      case "species":
        options = Object.keys(speciesConfig.species).filter((s) => {
          return filterByDeps(
            speciesObject,
            speciesConfig.species[s].dependencies
          )
        })
        break
      case "product":
        options = Object.keys(speciesConfig.products).filter((s) => {
          return filterByDeps(
            speciesObject,
            speciesConfig.products[s].dependencies
          )
        })
        break
      case "standards":
        options = Object.keys(speciesConfig.standards).filter((s) => {
          return filterByDeps(
            speciesObject,
            speciesConfig.standards[s].dependencies
          )
        })
        break
      case "siteTypes":
        options = Object.keys(speciesConfig.siteTypes).filter((s) => {
          return filterByDeps(
            speciesObject,
            speciesConfig.siteTypes[s].dependencies
          )
        })
        break
      default:
        break
    }

    options = options.map((option) => ({
      label: speciesConfig.codes[option],
      value: option,
    }))

    if (options.length === 0) {
      return <></>
    } else {
      if (Array.isArray(categoryValue)) {
        let values = categoryValue
          .map((value) => {
            return options.find((option) => option.value === value)
          })
          .filter((v) => v)
        return (
          <>
            <p>{label}</p>
            <MultiSelect
              className={`multiselect m-1 p-1 border border-gray-800 rounded bg-white ${
                newAssuranceFormOpen &&
                newAssuranceSpeciesIndex === assessmentSpeciesID
                  ? "input-disabled"
                  : ""
              }`}
              options={options}
              value={values || []}
              onChange={(e) => {
                onSpeciesChangeUpdateAssessmentSpecies(
                  assessmentSpeciesID,
                  category,
                  e,
                  speciesObject
                )
              }}
              labelledBy="Select..."
              ArrowRenderer={({ expanded }) => <div className="select-arrow" />}
              ClearSelectedIcon={<></>}
              disableSearch={true}
              hasSelectAll={false}
              disabled={
                newAssuranceFormOpen &&
                newAssuranceSpeciesIndex === assessmentSpeciesID
                  ? true
                  : false
              }
            />
          </>
        )
      } else {
        return (
          <>
            <p>{label}</p>
            <select
              className={`m-1 p-1 border border-gray-800 rounded bg-white`}
              value={categoryValue || ""}
              onChange={(e) => {
                onSpeciesChangeUpdateAssessmentSpecies(
                  assessmentSpeciesID,
                  category,
                  e.target.value,
                  speciesObject
                )
              }}
              disabled={
                newAssuranceFormOpen &&
                newAssuranceSpeciesIndex === assessmentSpeciesID
                  ? true
                  : false
              }
            >
              <option value="" hidden>
                Select...
              </option>
              {options.map((option, i) => (
                <option key={i} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </>
        )
      }
    }
  }

  const onSpeciesChangeUpdateAssessmentSpecies = (
    assessmentSpeciesID,
    category,
    value,
    speciesObject
  ) => {
    let updatedSpeciesObject = {
      category: "",
      species: "",
      product: "",
      standards: [""],
      siteTypes: [""],
      assurances: speciesObject.assurances,
      unique_id: speciesObject.unique_id,
    }

    switch (category) {
      case "category":
        updatedSpeciesObject.category = value
        // if (speciesObject.slapMark) {
        //   updatedSpeciesObject["slapMark"] = speciesObject?.slapMark
        // }

        break
      case "species":
        // if (speciesObject.slapMark) {
        //   updatedSpeciesObject["slapMark"] = speciesObject?.slapMark
        // }

        updatedSpeciesObject.category = speciesObject.category
        updatedSpeciesObject.species = value
        break
      case "product":
        // if (speciesObject.slapMark) {
        //   updatedSpeciesObject["slapMark"] = speciesObject?.slapMark
        // }

        updatedSpeciesObject.category = speciesObject.category
        updatedSpeciesObject.species = speciesObject.species
        updatedSpeciesObject.product = value
        break
      case "standards":
        // if (speciesObject.slapMark) {
        //   updatedSpeciesObject["slapMark"] = speciesObject?.slapMark
        // }
        //categoryConfig = speciesConfig['standards']
        updatedSpeciesObject.category = speciesObject.category
        updatedSpeciesObject.species = speciesObject.species
        updatedSpeciesObject.product = speciesObject.product
        updatedSpeciesObject.standards = value.flatMap((v) => v.value)
        updatedSpeciesObject.siteTypes = speciesObject.siteTypes
        break
      case "siteTypes":
        // if (speciesObject.slapMark) {
        //   updatedSpeciesObject["slapMark"] = speciesObject?.slapMark
        // }
        updatedSpeciesObject.category = speciesObject.category
        updatedSpeciesObject.species = speciesObject.species
        updatedSpeciesObject.product = speciesObject.product
        updatedSpeciesObject.standards = speciesObject.standards
        updatedSpeciesObject.siteTypes = value.flatMap((v) => v.value)
        break
      default:
        //categoryConfig = null
        break
    }

    let updatedAssessment = JSON.parse(JSON.stringify(assessment))
    updatedAssessment.subject.species[assessmentSpeciesID] =
      updatedSpeciesObject

    // if (category === "category" && value !== "PORK") {
    //   delete updatedAssessment.subject.species[assessmentSpeciesID]?.slapMark
    //   console.log("DELETE VALUE")
    // }

    setAssessment(updatedAssessment)
  }

  const addNewSpecies = () => {
    let updatedAssessment = JSON.parse(JSON.stringify(assessment))
    updatedAssessment.subject.species.push({
      category: "",
      species: "",
      product: "",
      standards: [""],
      siteTypes: [""],
      assurances: [],
    })
    setAssessment(updatedAssessment)
  }

  const removeSpecies = (assessmentSpeciesID) => {
    let updatedAssessment = JSON.parse(JSON.stringify(assessment))
    updatedAssessment.subject.species =
      updatedAssessment.subject.species.filter(
        (species, i) => i !== assessmentSpeciesID
      )
    setAssessment(updatedAssessment)
  }

  const updateAssessment = (property, value) => {
    let updatedAssessment = JSON.parse(JSON.stringify(assessment))
    updatedAssessment.subject[property] = value
    setAssessment(updatedAssessment)
  }

  const checkSpeciesExist = () => {
    if (assessment.subject.species) {
      return assessment.subject.species.some((specie) => {
        return specie.category !== "" && specie.species !== ""
      })
    }
  }

  const filterObjectByValueToArray = (object, condition) => {
    if (!Object.keys(object).length) {
      return false
    }
    const asArray = Object.entries(object)

    const filteredObject = asArray
      .filter(([key, value]) => value !== condition)
      .map((item) => item[1])

    return filteredObject
  }

  const saveProfile = (event) => {
    event.preventDefault()
    if (!checkSpeciesExist()) return

    let updatedAssessments = JSON.parse(JSON.stringify(assessments))
    let updatedAssessment = JSON.parse(JSON.stringify(assessment))
    let assessmentIndex = updatedAssessments.findIndex(
      (assmnt) => assmnt.unique_id == props.match.params.assessmentId
    )
    updatedAssessment.subject.species =
      updatedAssessment.subject.species.filter((s) => s)
    // check if surveys require standards as dependency
    const needsStandards = surveysDeps.includes("standards")
    const hasStandards = updatedAssessment.subject.species.filter(
      (s) =>
        filterAllByDeps(s, "standards", speciesConfig) // check has possible standards options
          ? !(s.standards?.length && s.standards[0]) // if so, check if has selected any of them
          : false // otherwise, we gucci
    )
    if (needsStandards && hasStandards.length !== 0) {
      setSurveyDepsError({
        for: "standards",
        species: hasStandards
          .map(
            (s) =>
              `${speciesConfig.codes[s.category]} - ${
                speciesConfig.codes[s.species]
              }`
          )
          .join(", "),
      })
      return
    }
    // check if surveys require siteTypes as dependency
    const needsSiteTypes = surveysDeps.includes("siteTypes")
    const hasSiteTypes = updatedAssessment.subject.species.filter(
      (s) =>
        filterAllByDeps(s, "siteTypes", speciesConfig) // check has possible standards options
          ? !(s.siteTypes?.length && s.siteTypes[0]) // if so, check if has selected any of them
          : false // otherwise, we gucci
    )
    if (
      hasStandards.length !== 0 ||
      (needsSiteTypes && hasSiteTypes.length !== 0)
    ) {
      setSurveyDepsError({
        for: "siteTypes",
        species: hasSiteTypes
          .map(
            (s) =>
              `${speciesConfig.codes[s.category]} - ${
                speciesConfig.codes[s.species]
              }`
          )
          .join(", "),
      })
      return
    }
    // Add Linked Holdings to profile
    const sanitizedLinkedHoldings = filterObjectByValueToArray(
      linkedHolding,
      false
    )
    updatedAssessment.subject.linkedHoldings = sanitizedLinkedHoldings || []

    if (!updatedAssessment.subject.linkedHoldings === []) {
      for (const [key, value] of Object.entries(slapMarksMap)) {
        updatedAssessment.subject.linkedHoldings[0].slapMarks[key] =
          Object.keys(value).map((key) => value[key]) || []
      }
    }

    if (updatedAssessment.subject?.submitted)
      updatedAssessment.subject.submitted = false
    updatedAssessments[assessmentIndex] = updatedAssessment
    localStorage.setItem(
      `profile_verified_${updatedAssessment.unique_id}`,
      JSON.stringify(updatedAssessment.subject)
    )
    setAssessments(updatedAssessments)
    console.log(
      props.match.params.assessmentId,
      "props.match.params.assessmentId"
    )
    history.push(`/assessment/${props.match.params.assessmentId}`)
  }

  const deleteSpeciesAssurance = (speciesIndex, assuranceIndex) => {
    let updatedAssessment = JSON.parse(JSON.stringify(assessment))
    updatedAssessment.subject.species[speciesIndex].assurances.splice(
      assuranceIndex,
      1
    )
    setAssessment(updatedAssessment)
  }

  const checkAssuranceValid = (speciesIndex, assuranceIndex) => {
    const species = assessment.subject.species[speciesIndex]
    const assurance =
      assessment.subject.species[speciesIndex].assurances[assuranceIndex]

    const valid = filterByDeps(
      species,
      speciesConfig.assurances[assurance.assurance].dependencies
    )

    return valid ? true : false
  }

  const ActionPopup = ({ message, confirmCallback }) => {
    return (
      <div className="fixed w-screen top-0 z-30 left-0 h-screen mx-auto bg-gray-300 bg-opacity-70 grid place-items-center">
        <div className="md:w-1/2 lg:w-1/4 bg-white border-2 rounded border-black p-5">
          <p className="text-center text-lg">{message}</p>
          <div className="flex flex-row justify-between mt-5">
            <button
              className="w-32 rounded-xl border-2 border-red-600 bg-red-600 text-white p-1 px-2"
              onClick={() => setConfirmPopup(null)}
            >
              {" "}
              Cancel{" "}
            </button>
            <button
              className="w-32 rounded-xl border-2 border-white bg-green-600 text-white p-1 px-2"
              onClick={() => {
                confirmCallback()
                setConfirmPopup(null)
              }}
            >
              {" "}
              Confirm{" "}
            </button>
          </div>
        </div>
      </div>
    )
  }

  const NewAssuranceForm = ({ speciesObject }) => {
    const [newAssurance, setNewAssurance] = useState({
      assurance: "",
      number: "",
      valid_from: "",
      expires_on: "",
    })

    const [errors, setErrors] = useState(null)

    const assurances = Object.keys(speciesConfig.assurances)
      .filter((assurance) => {
        return filterByDeps(
          speciesObject,
          speciesConfig.assurances[assurance].dependencies
        )
      })
      .sort()
      .map((assurance) => ({
        label: speciesConfig.codes[assurance],
        value: assurance,
      }))

    const validateDate = (date) => {
      let d = new Date(date)
      if (d === "Invalid Date") return "Please enter a valid date"
      if (d.getFullYear() < 1970 || d.getFullYear() > 2050)
        return "Year must between 1970 and 2050"
      return true
    }

    const validate = () => {
      let errors = {}
      let validateValidFrom
      let validateValidTo
      if (!newAssurance.assurance)
        errors.assurance = "You must select an assurance"
      if (!newAssurance.number || isNaN(newAssurance.number)) {
        errors.number = "You must enter a number"
      }
      if (newAssurance.valid_from > "") {
        validateValidFrom = validateDate(newAssurance.valid_from)
        if (validateValidFrom !== true) errors.valid_from = validateValidFrom
      }
      if (newAssurance.expires_on > "") {
        validateValidTo = validateDate(newAssurance.expires_on)
        if (validateValidTo !== true) errors.expires_on = validateValidTo
      }

      if (newAssurance.valid_from > "" && newAssurance.expires_on > "") {
        if (validateValidFrom === true && validateValidTo === true) {
          if (
            new Date(newAssurance.valid_from) >
            new Date(newAssurance.expires_on)
          ) {
            errors.valid_from = "Valid From cannot be greater than Valid To"
          }
        }
      }
      return Object.keys(errors).length > 0 ? errors : true
    }

    const saveAssurance = () => {
      let e = validate()
      if (e === true) {
        let updatedAssessment = JSON.parse(JSON.stringify(assessment))
        updatedAssessment.subject.species[
          newAssuranceSpeciesIndex
        ].assurances.push(newAssurance)
        setAssessment(updatedAssessment)
        setNewAssuranceFormOpen(false)
        setNewAssuranceSpeciesIndex(null)
      } else {
        setErrors(e)
      }
    }

    return (
      <>
        {assurances.length > 0 ? (
          <>
            <p>Assurance *</p>
            <select
              className={`m-1 p-1 border rounded bg-white ${
                errors?.assurance ? "border-red-600" : "border-gray-800"
              }`}
              value={newAssurance.assurance || ""}
              onChange={(e) =>
                setNewAssurance({ ...newAssurance, assurance: e.target.value })
              }
            >
              <option value="" hidden>
                Select...
              </option>
              {assurances.map((option, i) => (
                <option key={i} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            {errors && errors.assurance && (
              <p className="col-span-2 text-red-600 text-center">
                {errors.assurance}
              </p>
            )}
            <p>Number *</p>
            <input
              className={`m-1 p-1 border rounded bg-white ${
                errors?.number ? "border-red-600" : "border-gray-800"
              }`}
              value={newAssurance.number}
              onChange={(e) =>
                setNewAssurance({ ...newAssurance, number: e.target.value })
              }
            />
            {errors && errors.number && (
              <p className="col-span-2 text-red-600 text-center">
                {errors.number}
              </p>
            )}
            <p>Valid From</p>
            <input
              className={`m-1 p-1 border rounded bg-white ${
                errors?.valid_from ? "border-red-600" : "border-gray-800"
              }`}
              type="date"
              value={newAssurance.valid_from}
              onChange={(e) =>
                setNewAssurance({ ...newAssurance, valid_from: e.target.value })
              }
            />
            {errors && errors.valid_from && (
              <p className="col-span-2 text-red-600 text-center">
                {errors.valid_from}
              </p>
            )}
            <p>Valid To</p>
            <input
              className={`m-1 p-1 border rounded bg-white ${
                errors?.expires_on ? "border-red-600" : "border-gray-800"
              }`}
              type="date"
              value={newAssurance.expires_on}
              onChange={(e) =>
                setNewAssurance({ ...newAssurance, expires_on: e.target.value })
              }
            />
            {errors && errors.expires_on && (
              <p className="col-span-2 text-red-600 text-center">
                {errors.expires_on}
              </p>
            )}
            <div className="col-span-2 flex flex-row justify-evenly">
              <button
                className="py-2 mt-3 justify-self-center bg-red-600 w-32 rounded text-white"
                onClick={(e) => {
                  e.preventDefault()
                  setNewAssuranceFormOpen(false)
                  setNewAssuranceSpeciesIndex(null)
                }}
              >
                {" "}
                Cancel{" "}
              </button>
              <button
                className="py-2 mt-3 justify-self-center bg-green-600 w-32 rounded text-white"
                onClick={(e) => {
                  e.preventDefault()
                  saveAssurance(true)
                }}
              >
                {" "}
                Save{" "}
              </button>
            </div>
          </>
        ) : (
          <>
            <p className="col-span-2 text-center text-red-600">
              There are no assurances available for this species
            </p>
            <button
              className="col-span-2 py-2 mt-3 justify-self-center bg-red-600 w-32 rounded text-white"
              onClick={(e) => {
                e.preventDefault()
                setNewAssuranceFormOpen(false)
                setNewAssuranceSpeciesIndex(null)
              }}
            >
              {" "}
              Cancel{" "}
            </button>
          </>
        )}
      </>
    )
  }

  const addLinkedHolding = () => {
    const count = Object.keys(linkedHolding).length
    setLinkedHolding({ ...linkedHolding, [count]: {} })
  }

  return (
    <>
      <NavBar
        text={`Verify Farm Profile`}
        iconLeft="back"
        route={`/assessment/${props.match.params.assessmentId}`}
      />
      <form onSubmit={(e) => saveProfile(e)}>
        {assessment !== null && speciesConfig !== null && (
          <div className="mx-auto border-t-2 border-gray-500 md:border-t-0 md:w-1/2 lg:w-1/4 md:pt-5 grid grid-cols-1 gap-5 px-5">
            <div className="flex flex-col">
              <label className="font-md text-gray-600">Name*</label>
              <input
                required
                className="border border-gray-400 rounded p-2 w-full"
                defaultValue={assessment.subject.name}
                onChange={(e) => updateAssessment("name", e.target.value)}
              />
            </div>
            <div className="flex flex-col">
              <label className="font-md text-gray-600">Company Name</label>
              <input
                required
                className="border border-gray-400 rounded p-2 w-full"
                defaultValue={assessment.subject.business_name}
                onChange={(e) =>
                  updateAssessment("business_name", e.target.value)
                }
              />
            </div>
            <div className="flex flex-col">
              <label className="font-md text-gray-600">Address 1*</label>
              <input
                required
                className="border border-gray-400 rounded p-2 w-full"
                defaultValue={assessment.subject.address_1}
                onChange={(e) => updateAssessment("address_1", e.target.value)}
              />
            </div>
            <div className="flex flex-col">
              <label className="font-md text-gray-600">Address 2</label>
              <input
                className="border border-gray-400 rounded p-2 w-full"
                defaultValue={assessment.subject.address_2}
                onChange={(e) => updateAssessment("address_2", e.target.value)}
              />
            </div>
            <div className="flex flex-col">
              <label className="font-md text-gray-600">City*</label>
              <input
                required
                className="border border-gray-400 rounded p-2 w-full"
                defaultValue={assessment.subject.city}
                onChange={(e) => updateAssessment("city", e.target.value)}
              />
            </div>
            <div className="flex flex-col">
              <label className="font-md text-gray-600">County*</label>
              <input
                required
                className="border border-gray-400 rounded p-2 w-full"
                defaultValue={assessment.subject.county}
                onChange={(e) => updateAssessment("county", e.target.value)}
              />
            </div>
            <div className="flex flex-col">
              <label className="font-md text-gray-600">Postcode*</label>
              <input
                required
                className="border border-gray-400 rounded p-2 w-full"
                defaultValue={assessment.subject.postcode}
                onChange={(e) => updateAssessment("postcode", e.target.value)}
              />
            </div>
            <div className="flex flex-col">
              <label className="font-md text-gray-600">Country*</label>
              <input
                required
                className="border border-gray-400 rounded p-2 w-full"
                defaultValue={assessment.subject.country}
                onChange={(e) => updateAssessment("country", e.target.value)}
              />
            </div>
            <div className="flex flex-col">
              <label className="font-md text-gray-600">Species*</label>
              {!checkSpeciesExist() && (
                <p className="text-red-600">You must add a Species</p>
              )}
              {assessment.subject.species.length > 0 && (
                <div className="grid grid-cols-1 gap-5">
                  {assessment.subject.species.map((species, i) => {
                    let invalidAssuranceCount = 0
                    return (
                      <div
                        key={i}
                        className="p-2 rounded border-2 grid grid-cols-2 items-center"
                      >
                        <div className={`relative col-span-2 h-auto`}>
                          {/* {species.assurances.length > 0 &&
                          <p className="text-red-600 text-center">This species is linked to one or more assurances, it cannot be edited unless you delete the assurances</p>
                        } */}
                          <button
                            className="absolute bg-white"
                            style={{
                              top: "-27px",
                              right: "-27px",
                            }}
                            onClick={(e) => {
                              e.preventDefault()
                              setConfirmPopup({
                                message:
                                  "Are you sure you want to delete this species and its assurances?",
                                confirmCallback: () => removeSpecies(i),
                              })
                            }}
                          >
                            <RiCloseCircleFill
                              size="36px"
                              className="text-red-600"
                            />
                          </button>
                        </div>
                        {speciesSelect(i, "Category", "category", species)}
                        {speciesSelect(i, "Species", "species", species)}
                        {speciesSelect(i, "Product", "product", species)}
                        {speciesSelect(i, "Standards", "standards", species)}
                        {speciesSelect(i, "Site Types", "siteTypes", species)}
                        {species.assurances.length > 0 && (
                          <div className="col-span-2">
                            <hr className="my-4" />
                            <label className="font-md text-gray-600">
                              Assurances
                            </label>
                            {species.assurances.map((assurance, j) => {
                              const assuranceValid = checkAssuranceValid(i, j)
                              if (!assuranceValid) invalidAssuranceCount += 1
                              return (
                                <div key={j} className={`mt-2 flex flex-row`}>
                                  {!assuranceValid && (
                                    <div className="justify-self-start self-center w-10">
                                      <FaExclamation
                                        className="m-auto text-red-600"
                                        size="20px"
                                      />
                                    </div>
                                  )}
                                  <div className="justify-self-start flex-grow">
                                    <p>
                                      {speciesConfig.codes[assurance.assurance]}
                                    </p>
                                    <p>No. {assurance.number}</p>
                                    {assurance.valid_from && (
                                      <p>
                                        Valid:{" "}
                                        {new Date(
                                          assurance.valid_from
                                        ).toLocaleDateString()}
                                      </p>
                                    )}
                                    {assurance.expires_on && (
                                      <p>
                                        Expires:{" "}
                                        {new Date(
                                          assurance.expires_on
                                        ).toLocaleDateString()}
                                      </p>
                                    )}
                                  </div>
                                  <button
                                    className="justify-self-end	self-center rounded-xl border-2 border-red-600 bg-red-600 text-white p-1 px-2"
                                    onClick={(e) => {
                                      e.preventDefault()
                                      setConfirmPopup({
                                        message:
                                          "Are you sure you want to delete this assurance?",
                                        confirmCallback: () =>
                                          deleteSpeciesAssurance(i, j),
                                      })
                                    }}
                                  >
                                    {" "}
                                    Delete{" "}
                                  </button>
                                </div>
                              )
                            })}
                          </div>
                        )}
                        {invalidAssuranceCount > 0 && (
                          <p className="col-span-2 text-red-600 text-center mt-3">
                            There are assurances that are not valid for this
                            species. They will be deleted.
                          </p>
                        )}
                        {newAssuranceFormOpen &&
                          newAssuranceSpeciesIndex === i && (
                            <div className="col-span-2 grid grid-cols-2 items-center">
                              <hr className="col-span-2 my-3" />
                              <NewAssuranceForm speciesObject={species} />
                            </div>
                          )}
                        {newAssuranceSpeciesIndex !== i && (
                          <button
                            type="submit"
                            className="py-2 mt-3 justify-self-center col-span-2 bg-green-600 w-32 rounded text-white"
                            onClick={(e) => {
                              e.preventDefault()
                              setNewAssuranceFormOpen(true)
                              setNewAssuranceSpeciesIndex(i)
                            }}
                          >
                            {" "}
                            Add Assurance{" "}
                          </button>
                        )}
                      </div>
                    )
                  })}
                </div>
              )}
              <button
                className="mt-5 py-2 rounded border-2 border-secondary text-xl"
                onClick={(e) => {
                  e.preventDefault()
                  addNewSpecies()
                }}
              >
                {" "}
                Add Species{" "}
              </button>
            </div>
            {hasPork && slapMarksMap && (
              <SlapMark
                slapMarksMap={slapMarksMap}
                setSlapMarksMap={setSlapMarksMap}
              ></SlapMark>
            )}
            <div className="flex flex-col">
              <label className="font-md text-gray-600">Linked Holdings</label>
              {linkedHolding &&
                Object.keys(linkedHolding)?.map((index) => {
                  if (linkedHolding[index]) {
                    return (
                      <div key={index}>
                        <AddLinkedHolding
                          index={index}
                          linkedHolding={linkedHolding}
                          setLinkedHolding={setLinkedHolding}
                          assessment={assessment}
                          slapMarksMap={slapMarksMap}
                          setSlapMarksMap={setSlapMarksMap}
                          hasPork={hasPork}
                        />
                      </div>
                    )
                  }
                })}
            </div>
            <button
              className="py-2 rounded border-2 border-secondary text-xl"
              onClick={(e) => {
                e.preventDefault()
                addLinkedHolding()
                // addNewSpecies()
              }}
            >
              Add Linked Holding
            </button>
            <div className="flex flex-col">
              <label className="font-md text-gray-600">* Required Fields</label>
            </div>
          </div>
        )}

        {surveyDepsError?.for === "standards" && (
          <div className="text-center text-red-600 mt-6">
            Standards selection required for {surveyDepsError.species}
          </div>
        )}

        {surveyDepsError?.for === "siteTypes" && (
          <div className="text-center text-red-600 mt-6">
            Site type selection required for {surveyDepsError.species}
          </div>
        )}
        <div className="my-6 mt-6 mx-auto md:w-1/2 lg:w-1/4 flex flex-row flex-nowrap justify-between px-5">
          <button
            className="w-36 rounded-xl border-2 border-red-600 bg-red-600 text-white p-2 px-3  text-2xl font-semibold"
            onClick={(e) => {
              e.preventDefault()
              history.push(`/assessment/${props.match.params.assessmentId}`)
            }}
          >
            {" "}
            Cancel{" "}
          </button>
          <button
            // disabled={!Object.keys(slapMarksMap).length && hasPork}
            className="w-36 rounded-xl border-2 border-green-600 p-2 px-3 text-2xl font-semibold"
          >
            Save
          </button>
        </div>
      </form>

      {confirmPopup !== null && (
        <ActionPopup
          message={confirmPopup.message}
          confirmCallback={confirmPopup.confirmCallback}
        />
      )}
    </>
  )
}

import React from "react"

const Standards = (props) => {
  return (
    <div>
      <div className="my-4">
        <div className="w-full flex justify-center flex-wrap">
          {props.standards.map((standard) => {
            return (
              <div className="bg-gray-300 text-gray-800 text-sm font-medium mr-2 px-2.5 py-0.5 my-1 rounded">
                {`
                ${standard.title} (${standard.number} ${standard.species.join(
                  ", "
                )})`}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Standards

import React from "react"
import { useStore } from "react-context-hook"
import { MdAccountCircle } from "react-icons/md"

export const AccountIcon = () => {
  const [showAccountModal, setShowAccountModal] = useStore(
    "showAccountModal",
    false
  )

  return (
    <div
      className="w-10 h-full pt-1"
      onClick={(e) => setShowAccountModal(true)}
    >
      <MdAccountCircle className="text-4xl mx-auto md:cursor-pointer" />
    </div>
  )
}

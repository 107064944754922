import React, { useEffect, useRef, useState } from "react"
import { QuestionStatus } from "../saveQuestion"
import TranslationWrapper from "../../../utils/translationWrapper"
import { HasAnswerChangedHook } from "../helpers/hasAnswerChangedHook"

export const DistributionQuestion = (props) => {
  const [answer, setAnswer] = useState(props.answer || [])

  const [saved, setSaved] = useState(false)
  const [valid, setValid] = useState(true)
  const [errorMessage, setErrorMessage] = useState(null)
  const [saveButtonText, setSaveButtonText] = useState("Save")
  const [saveButtonColour, setSaveButtonColour] = useState("bg-primary")
  const [isNumeric, setIsNumeric] = useState(false)
  const [placeholderText, setPlaceholderText] = useState("")

  const refKey = props.changeAnswerKey

  useEffect(() => {
    if (props.answer) setSaved(true)
    determineIfNumeric()
  }, [])

  HasAnswerChangedHook(answer, refKey, props.questionRef, props.question.id)

  const determineIfNumeric = () => {
    if (props.question.properties.is_numeric) {
      setIsNumeric(true)
      setPlaceholderText("Type a number...")
    } else {
      setIsNumeric(false)
      setPlaceholderText("Type your answer...")
    }
  }

  const updateAnswer = (i, e) => {
    let newAnswer = [...answer]
    newAnswer[i] = e.target.value
    setAnswer(newAnswer)
    setSaved(false)
  }

  const saveAnswer = () => {
    const empty = answer.filter((a) => a === "")
    const numeric = answer.filter((a) => {
      if (+a === +a) return a
    })
    const text = answer.filter((a) => {
      if (isNaN(a)) return a
    })
    if (
      answer.length === 0 ||
      empty.length !== 0 ||
      (isNumeric &&
        numeric.length !== props.question.properties.categories.length)
    ) {
      setValid(false)
      if (text.length > 0) {
        setErrorMessage("Answers must be numeric.")
      }
      if (
        text.length === 0 ||
        answer.length < props.question.properties.categories.length
      ) {
        setErrorMessage("Answers must not be blank.")
      }
      setSaveButtonColour("bg-red-600")
      setSaveButtonText("Error!")

      props.removeFromAnswers()
      setSaved(false)
    } else {
      setValid(true)
      setErrorMessage(null)
      setSaveButtonColour("bg-green-600")
      setSaveButtonText("Saved!")

      props.saveToAnswers(answer)
      setSaved(true)
    }

    setTimeout(() => {
      setSaveButtonColour("bg-primary")
      setSaveButtonText("Save")
    }, 1000)
  }

  return (
    <div>
      <div>
        {props.question.properties.categories.map((cat, i) => (
          <div key={i}>
            <div className="text-left font-semibold text-gray-600 my-2">
              <TranslationWrapper
                translations={props.survey.survey_schema_translations}
              >
                {cat}
              </TranslationWrapper>
            </div>
            <input
              placeholder={placeholderText}
              className="w-full outline-none border-2 border-gray-300 focus:border-2 focus:border-yellow-500 focus:rounded rounded-lg p-2 text-left"
              value={answer[i] || ""}
              onChange={(e) => updateAnswer(i, e)}
              autoComplete="off"
            />
          </div>
        ))}
      </div>
      <QuestionStatus
        question={props.question}
        answer={answer}
        saveAnswer={saveAnswer}
        saved={saved}
        saveButtonColour={saveButtonColour}
        setSaveButtonColour={setSaveButtonColour}
        saveButtonText={saveButtonText}
        setSaveButtonText={setSaveButtonText}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        comment={props.comment}
        saveComment={props.saveComment}
        saveStandard={props.saveStandard}
        conformance={props.conformance}
        saveConformance={props.saveConformance}
        changeAnswerKey={props.changeAnswerKey}
        questionRef={props.questionRef}
      />
    </div>
  )
}

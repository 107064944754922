import React, { useEffect, useState, useRef, useMemo } from "react"
import { useStore } from "react-context-hook"

import { AccountIcon } from "./accountIcon"
import { RefreshIcon } from "./refreshIcon"
import { BackIcon } from "./backIcon"
import { ModalWrapper } from "./modal"
import { Prompt, useHistory, useLocation, useParams } from "react-router-dom"
import HasAnswerChangedModal from "./modal/hasAnswerChangedModal"

const PATH_KEY_WORDS = [
  ["assessment", "survey", "section"],
  ["assessment", "review"],
]

export const NavBar = (props) => {
  const [showAnswerChangeModal, setShowAnswerChangeModal] = useState(false)
  const [allowPrompt, setAllowPrompt] = useState(false)

  const [hasAnswersChangedMap, setHasAnswersChangedMap] = useStore(
    "hasAnswersChangedMap"
    // {}
  )

  const [hasAnswersChanged, setHasAnswersChanged] = useStore(
    "hasAnswersChanged"
    // false
  )

  const history = useHistory()

  const checkKeyWordsExistInPath = (keywordArray, path) => {
    for (let i = 0; i < keywordArray.length; i++) {
      const keyWords = keywordArray[i]
      const hasMatched = keyWords.every((word) => {
        return path.includes(word)
      })

      if (hasMatched) {
        console.log("match")
        setAllowPrompt(true)
        break
      }
    }
  }

  const splitPath = useMemo(() => {
    const currentPath = history.location.pathname

    return currentPath.split("/").filter((p) => 32 > p.length && p.length > 1)
  }, [history.location.pathname])

  useEffect(() => {
    checkKeyWordsExistInPath(PATH_KEY_WORDS, splitPath)
  }, [history.location.pathname])

  return (
    <>
      {!showAnswerChangeModal && allowPrompt && (
        <Prompt
          when={hasAnswersChanged}
          message={(location, action) => {
            if (action === "POP") {
              setHasAnswersChanged(false)
              setHasAnswersChangedMap({})
            }

            return "Confirm you want to leave this page, any unsaved answers will be lost."
          }}
        />
      )}
      <div className="bg-nav pl-5 pr-7 flex text-center font-semibold text-lg text-gray-100">
        <div className="w-8 align-middle">
          {props.iconLeft === "refresh" && <RefreshIcon />}

          {hasAnswersChanged && props.iconLeft === "back" && (
            <BackIcon setShowAnswerChangeModal={setShowAnswerChangeModal} />
          )}

          {!hasAnswersChanged && props.iconLeft === "back" && (
            <BackIcon route={props.route} />
          )}
        </div>
        <div className="w-full align-middle p-2 pl-14">
          <span>{props.text}</span>
        </div>
        <div className="w-12 align-middle m-auto text-sm">
          v{process.env.REACT_APP_VERSION}
        </div>
        <div className="w-8 align-middle">
          {props.iconRight === "account" && <AccountIcon />}
        </div>
      </div>

      {showAnswerChangeModal && (
        <HasAnswerChangedModal
          pushTo={props.route}
          setShowModal={setShowAnswerChangeModal}
        />
      )}
      {/* {!showAnswerChangeModal && (
        <Prompt
          when={hasAnswersChanged}
          message={(location, action) => {
            if (action === "POP") {
              setHasAnswersChanged(false)
              setHasAnswersChangedMap({})
            }

            return "Confirm you want to leave this page, any unsaved answers will be lost."
          }}
        />
      )} */}
      {/* {showAnswerChangeModal && (
        <ModalWrapper showModal={setShowAnswerChangeModal}>
          <div className="text-gray-700 text-lg font-semibold flex flex-col items-center text-center">
            You have unsaved answers that will be lost when leaving this page.
          </div>
          <div className="flex justify-evenly pt-6 text-lg lg:text-2xl">
            <button
              disabled={false}
              className="rounded-xl p-1 px-5 mr-2 text-white  font-semibold bg-primary"
              onClick={() => {
                setHasAnswersChanged(false)
                setHasAnswersChangedMap({})
                history.push(props.route)
              }}
            >
              Continue without saving
            </button>

            <button
              className="rounded-xl p-1 px-5 text-white font-semibold bg-gray-400"
              onClick={() => {
                setShowAnswerChangeModal(false)
              }}
            >
              Cancel
            </button>
          </div>
        </ModalWrapper>
      )} */}
    </>
  )
}

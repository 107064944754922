import { useEffect, useRef, useState } from "react"

export const ConformanceReview = ({
  conformance: conformance_,
  saveConformance,
  saved,
  setSaved,
  setHasAnswersChangedMap,
  hasAnswersChangedMap,
  question,
  refList,
  setRefList,
  getChangedAnswerKey,
}) => {
  const [conformance, setConformance] = useState(conformance_)
  const [commentCopy, setCommentCopy] = useState(null)
  // const [saved, setSaved] = useState(false)

  const questionRef = useRef()
  const initialRender = useRef(true)

  const handleCommentChange = (comment) => {
    setConformance((curr) => ({ ...curr, comment }))
    setSaved(false)
  }

  const handleSave = () => {
    saveConformance(conformance)
    // setSaved(true)
    // setTimeout(() => {
    //   setSaved(false)
    // }, 1000)
  }

  useEffect(() => {
    const key = getChangedAnswerKey(question)

    initialRender.current = false

    if (!commentCopy && commentCopy !== "") {
      let copy = conformance.comment || ""
      setCommentCopy(copy)
    }

    if (commentCopy !== null && conformance.comment !== commentCopy) {
      setHasAnswersChangedMap({
        ...hasAnswersChangedMap,
        [key]: {
          ...hasAnswersChangedMap[key],
          comment: true,
        },
      })
    } else if (conformance.comment === commentCopy) {
      setHasAnswersChangedMap({
        ...hasAnswersChangedMap,
        [key]: {
          ...hasAnswersChangedMap[key],
          comment: false,
        },
      })
    }
    if (!initialRender.current) {
      setRefList({ ...refList, [key]: questionRef })
    }
  }, [conformance])

  return (
    <div className="text-left">
      {/* <form className="flex py-2" onChange={e => {handleStatusChange(e.target.value)}}>
        <p className="mr-6">Conformant:</p>
        <label className="m-auto">
          <input type="radio" disabled value={true} name="conformant"
            defaultChecked={conformance.hasOwnProperty('conformant') ? JSON.parse(conformance.conformant) : false} /> Yes
        </label>
        <label className="m-auto">
          <input type="radio" disabled value={false} name="conformant" 
            defaultChecked={conformance.hasOwnProperty('conformant') ? !JSON.parse(conformance.conformant) : false} /> No
        </label>
      </form> */}
      <textarea
        value={conformance.comment || ""}
        onChange={(e) => handleCommentChange(e.target.value)}
        className="w-full border-2 rounded-lg border-gray-300 p-2 mt-3"
        rows="4"
        placeholder="Comments..."
      />

      <div className="mt-8 text-center">
        <button
          type="button"
          className={`transition duration-150 rounded-lg ${
            saved ? "bg-green-600" : "bg-primary"
          } p-2 w-24 text-white font-semibold`}
          onClick={handleSave}
        >
          {saved ? "Saved" : "Save"}
        </button>
      </div>
    </div>
  )
}

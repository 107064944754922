import React, { useEffect, useRef, useState } from "react"
import { useStore } from "react-context-hook"

export const UnsavedAnswerBanner = ({ children }) => {
  const [unsavedAnswers, setUnsavedAnswers] = useState([])
  const [count, setCount] = useState(0)
  const [hasAnswersChangedMap, setHasAnswersChangedMap] = useStore(
    "hasAnswersChangedMap"
  )

  const [hasAnswersChanged, setHasAnswersChanged] = useStore(
    "hasAnswersChanged"
    // false
  )

  const [refList, setRefList] = useStore(
    "refList"
    //  {}
  )
  // const isMountedRef = useRef("false")

  useEffect(() => {
    const temp = []
    if (hasAnswersChangedMap) {
      for (const [key, value] of Object.entries(hasAnswersChangedMap)) {
        if (value.comment || value.answer) {
          if (!temp.includes(key)) {
            temp.push(key)
          }
        }
      }
    }
    setUnsavedAnswers(temp)
  }, [hasAnswersChangedMap])

  const checkForUnmountedAnswers = () => {
    if (!!refList[unsavedAnswers[count]]?.current === false) {
      const offScreenAnswers = []
      unsavedAnswers.forEach((USAnswer) => {
        if (!!refList[USAnswer]?.current === false) {
          offScreenAnswers.push(USAnswer)
        }
      })

      let temp = { ...hasAnswersChangedMap }

      offScreenAnswers.forEach((OSAnswer) => {
        temp = { ...temp, [OSAnswer]: { comment: false } }
      })

      setHasAnswersChangedMap({ ...temp })
      setCount(() => 0)
    }
  }

  const handleScroll = () => {
    checkForUnmountedAnswers()

    if (refList[unsavedAnswers[count]]) {
      refList[unsavedAnswers[count]]?.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      })
      if (count < unsavedAnswers.length - 1) {
        setCount((curr) => curr + 1)
      } else {
        setCount(() => 0)
      }
    }
  }

  return (
    <>
      {hasAnswersChanged && (
        <div
          //ref={isMountedRef}
          className="w-full h-12 bg-orange-600 sticky top-0 z-50"
        >
          <div className=" h-full flex justify-center items-center">
            <div className="text-xl text-white font-medium text-center">
              You have unsaved answers{" "}
              <span onClick={handleScroll} style={{ cursor: "pointer" }}>
                <u>View</u>
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

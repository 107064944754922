import { useState, useEffect, useCallback } from "react"
import { useStore } from "react-context-hook"
import { useParams } from "react-router-dom"

export function HasAnswerChangedHook(answer, key, questionRef, questionId) {
  const [answerCopy, setAnswerCopy] = useState(null)
  const [answers, setAnswers] = useStore("answers", null)
  const [refList, setRefList] = useStore("refList")
  const [hasAnswersChangedMap, setHasAnswersChangedMap] = useStore(
    "hasAnswersChangedMap",
    {}
  )
  const [hasAnswersChanged, setHasAnswersChanged] = useStore(
    "hasAnswersChanged",
    false
  )

  const params = useParams()

  const findQuestion = () => {
    const { assessmentId, sectionId, surveyId } = params
    return answers[surveyId].schema.sections[sectionId - 1].questions.find(
      (question) => {
        return question.id === questionId
      }
    )
  }

  const hasAnswersChangedHelper = useCallback(() => {
    if (hasAnswersChangedMap) {
      for (const [key, value] of Object.entries(hasAnswersChangedMap)) {
        if (value.answer || value.comment) {
          setHasAnswersChanged(true)
          break
        }
        setHasAnswersChanged(false)
      }
    }
  }, [hasAnswersChangedMap])

  useEffect(() => {
    const { assessmentId, sectionId, surveyId } = params
    let storageAnswer
    try {
      const question = findQuestion()
      storageAnswer = question.answer
    } catch (error) {
      storageAnswer = false
    }
    // try {
    //   storageAnswer = answers[surveyId].schema.sections[
    //     sectionId - 1
    //   ].questions.find((question) => {
    //     return question.id === questionId
    //   })?.answer
    // } catch (error) {
    //   storageAnswer = false
    // }

    if (storageAnswer) {
      const question = findQuestion()
      let copy = storageAnswer
      switch (question.type) {
        case "number":
          setAnswerCopy(copy.number)
          break

        default:
          setAnswerCopy(copy)
          break
      }
    } else {
      let copy = answer || ""
      setAnswerCopy(copy)
    }
  }, [answers])

  useEffect(async () => {
    if (answerCopy !== null && String(answer) !== String(answerCopy)) {
      console.log("a =>", answer, "----", "c =>", answerCopy)
      console.log(1)
      setHasAnswersChangedMap({
        ...hasAnswersChangedMap,
        [key]: {
          ...hasAnswersChangedMap[key],
          answer: true,
        },
      })
      hasAnswersChangedHelper()
    } else if (String(answer) === String(answerCopy)) {
      console.log("a =>", answer, "----", "c =>", answerCopy)
      console.log(2)
      setHasAnswersChangedMap({
        ...hasAnswersChangedMap,
        [key]: {
          ...hasAnswersChangedMap[key],
          answer: false,
        },
      })
      hasAnswersChangedHelper()
    }
    setRefList({ ...refList, [key]: questionRef })
  }, [answer])

  return true
}

import React, { useEffect, useState, useRef } from "react"
import { useStore } from "react-context-hook"

import { useParams } from "react-router-dom"
import { CommentBox } from "./commentBox"
import { HasCommentChangedHook } from "./helpers/hasCommentChangedHook"

const NonConformantCommentBox = (props) => {
  const [answers, setAnswers] = useStore("answers", null)
  // const [comment, setComment] = useState("")
  // const [copyOfComment, setCopyOfComment] = useState(null)

  const { comment, setComment, title, placeholder } = props

  const params = useParams()
  const refKey = props.changeAnswerKey

  const getSavedStandardComment = () => {
    const sectionId = parseInt(params.sectionId.split("?").at(0)) - 1

    try {
      return (
        answers[params.surveyId]?.schema.sections[sectionId]?.questions.find(
          (q) => q.id === props.question.id
        )?.actions?.comment ?? ""
      )
    } catch (e) {
      return ""
    }
  }

  useEffect(() => {
    setComment(getSavedStandardComment())
  }, [])

  useEffect(() => {
    handleUpdateStandardsComment(comment)
  }, [comment])

  HasCommentChangedHook(
    comment,
    refKey,
    props.questionRef,
    getSavedStandardComment
  )

  const handleCommentChange = (c) => {
    setComment(c)
  }
  const handleUpdateStandardsComment = (comment) => {
    let standards = {
      standards: props.question.standards,
      comment: comment,
    }
    props.setStandards(standards)
  }

  return (
    // <div>
    //   <CommentBox
    //     comment={comment}
    //     setComment={setComment}
    //     title={props.title}
    //     placeholder={props.placeholder}
    //   />
    // </div>
    <div className="text-left">
      <div className="flex py-2">
        <p className="mr-6">{title}</p>
      </div>
      <textarea
        value={comment}
        onChange={(e) => handleCommentChange(e.target.value)}
        className="w-full border-2 rounded-lg border-gray-300 p-2"
        rows="2"
        placeholder={placeholder}
      />
    </div>
  )
}

export default NonConformantCommentBox

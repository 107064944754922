import React, { useEffect, useState } from "react"
import { useStore } from "react-context-hook"
import { useHistory } from "react-router-dom"
import { Header } from "../components/header"
import { NavBar } from "../components/navbar"
import { SurveysListItem } from "../components/surveysListItem"
import { FaAngleRight } from "react-icons/fa"
import {
  checkSurveyCompleted,
  evaluateQuestionDisplayConditions,
  getCurrentSurveyAnswersNumber,
  useOnlineStatus,
} from "../../utils"
import { useLiveQuery } from "dexie-react-hooks"
import { db } from "../../utils/indexedDb"
import DataLoadingContainer from "../utils/loader/DataLoadingContainer"
import TranslationWrapper from "../utils/translationWrapper"

const getSurveys = (account, surveys) => {
  const options = {
    credentials: "include",
  }
  return Promise.all(
    surveys.map((survey) =>
      fetch(
        `${process.env.REACT_APP_API_HOST}/${account.tenant.identifier}/survey/${survey.unique_id}`,
        options
      )
        .then((res) => res.json())
        .then((res) => {
          // res.data[0].unique_id = survey.unique_id
          // res.data[0].submitted = survey.submitted || false
          // filter surveys for ones where current user is assessor
          if (res.status === 200 && res.data?.length) {
            if (
              account.role === "admin" ||
              res.data[0].assessors.some((a) => a.email === account.email)
            )
              return res
          }
          return null
        })
    )
  )
}

const getFarmManagers = (farmID, tenant) => {
  return fetch(
    `${process.env.REACT_APP_API_HOST}/${tenant}/farms/${farmID}/users`,
    { credentials: "include" }
  )
    .then((res) => res.json())
    .then((res) =>
      res.data?.filter((u) => u.manager && JSON.parse(u.manager) == true)
    )
}

export const Capture = (props) => {
  const [account, setAccount] = useStore("account", null)
  const [captureGroups, setCaptureGroups] = useStore("captureGroups", null)
  const [captureGroup, setCaptureGroup] = useStore("captureGroup", null)
  const [managers, setManagers] = useState(null)
  const [surveys, setSurveys] = useStore("surveys", null)
  const [answers, setAnswers] = useStore("answers", null)
  const [thisSurveys, setThisSurveys] = useState([])
  const [pendingSurveys, setPendingSurveys] = useState(null)
  const [completedSurveys, setCompletedSurveys] = useState([])
  const [stashedSurveys, setStashedSurveys] = useState([])
  const [showConfirm, setShowConfirm] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [submitError, setSubmitError] = useState(false)
  const [notSubmittedSurveys, setNotSubmittedSurveys] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const online = useOnlineStatus()
  const allFiles = useLiveQuery(() => db.items.toArray(), [])
  const history = useHistory()

  useEffect(() => {
    if (captureGroups) {
      const thisCaptureGroup = captureGroups.filter(
        (cg) => cg.unique_id == props.match.params.captureGroupId
      )[0]
      setCaptureGroup(thisCaptureGroup)
      let thisS = thisCaptureGroup.surveys.map((survey) => {
        return surveys.find((s) => s.unique_id === survey.unique_id)
      })
      setThisSurveys(thisS)
      setManagers(thisCaptureGroup.subject.managers)
    }
  }, [captureGroups])

  useEffect(() => {
    if (surveys && thisSurveys && captureGroup) {
      thisSurveys.sort((a, b) => {
        const hasListPriority = (survey) =>
          survey.schema.hasOwnProperty("list_priority")

        if (hasListPriority(a) && hasListPriority(b)) {
          return a.schema.list_priority - b.schema.list_priority
        } else if (hasListPriority(a) && !hasListPriority(b)) {
          return -1
        } else if (!hasListPriority(a) && hasListPriority(b)) {
          return 1
        } else if (!hasListPriority(a) && !hasListPriority(b)) {
          return 0
        }
      })

      setPendingSurveys(thisSurveys.filter((s) => !s.submitted))
      if (answers) {
        setCompletedSurveys(getCompletedSurveysFromAnswers())
        setStashedSurveys(getStashedSurveysFromAnswers())
      }
    }
  }, [thisSurveys, answers, captureGroup])

  const getCompletedSurveysFromAnswers = (forSubmission = false) => {
    let surveys = []
    thisSurveys.forEach((survey) => {
      // Only submit those surveys that are completed and that haven't been submitted already
      if (
        checkSurveyCompleted(
          survey,
          answers[survey.unique_id],
          answers,
          captureGroup.subject.species
        ) &&
        !survey.submitted
      ) {
        if (forSubmission) {
          let now = new Date()
          answers[survey.unique_id].submission_timestamp = now.toISOString()
          answers[survey.unique_id].schema.completed_by = account
        }
        surveys.push(answers[survey.unique_id])
      }
    })
    return surveys
  }

  const getStashedSurveysFromAnswers = (forSubmission = false) => {
    let surveys = []
    // Get stashed surveys
    Object.keys(answers).forEach((id) => {
      let matches = /(.*)(\/\/)(\d+)/g.exec(id)
      if (matches && matches[3] && parseInt(matches[3])) {
        if (forSubmission && !answers[id].submission_timestamp) {
          let now = new Date()
          answers[id].submission_timestamp = now.toISOString()
        }
        surveys.push(answers[id])
      }
    })
    return surveys
  }

  // #region SUBMIT
  const submitFiles = () => {
    setIsLoading(true)
    return Promise.all(
      allFiles.map((file) => {
        const formData = new FormData()
        formData.append(file.unique_id, file.data)
        return fetch(
          `${process.env.REACT_APP_API_HOST}/${account.tenant.identifier}/capture/submitFile/${file.unique_id}`,
          {
            method: "POST",
            body: formData,
            credentials: "include",
          }
        )
      })
    )
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const submitSurveys = () => {
    setIsLoading(true)
    setSubmitted(true)
    const data = [
      ...getCompletedSurveysFromAnswers(true),
      ...getStashedSurveysFromAnswers(true),
    ]

    return fetch(
      `${process.env.REACT_APP_API_HOST}/${account.tenant.identifier}/capture/${captureGroup.unique_id}/submit`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          surveys: data,
          subjectProfile: captureGroup.subject,
        }),
        credentials: "include",
      }
    )
      .then((res) => res.json())
      .finally(() => {
        setIsLoading(false)
      })
  }

  const submit = () => {
    setIsLoading(true)
    setSubmitError(false)
    Promise.all([submitSurveys(), submitFiles()])
      .then(([resS, resF]) => {
        if (resS.status === 200) {
          resS.data.notSubmittedSurveys.length > 0 &&
            setNotSubmittedSurveys(resS.data.notSubmittedSurveys)
          if (resS.status === 200 && resS.data.surveyIDLookup) {
            let answers_ = { ...answers }
            Object.keys(resS.data.surveyIDLookup).forEach((id) => {
              delete answers_[id]
            })
            Object.keys(answers_).forEach((id) => {
              let matches = /(.*)(\/\/)(\d+)/g.exec(id)
              if (
                matches &&
                Object.keys(resS.data.surveyIDLookup).includes(matches[1])
              ) {
                delete answers_[id]
              }
            })
            localStorage.setItem("answers", JSON.stringify(answers_))
            setAnswers(answers_)
            setShowConfirm(false)
            setSubmitted(false)

            console.log("Submitted surveys and deleted answers.")
          }
        } else {
          setSubmitted(false)
          setSubmitError(true)
          console.log("Error submitting capture group surveys...")
        }
      })
      .catch((error) => {
        if (!window.navigator.onLine) {
          console.log("Scheduled for later")
          setSubmitted(true)
          setShowConfirm(false)
        } else {
          console.log("Error submitting capture group surveys...")
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  // #endregion

  const initializeNewSurvey = (survey) => {
    let old_survey = answers[survey.unique_id]
    if (
      old_survey &&
      checkSurveyCompleted(
        survey,
        old_survey,
        answers,
        captureGroup.subject.species
      )
    ) {
      let next_number =
        getCurrentSurveyAnswersNumber(answers, survey.unique_id) + 1
      let new_answers = {
        ...answers,
        [`${old_survey.unique_id}//${next_number}`]: old_survey,
      }
      delete new_answers[old_survey.unique_id]
      localStorage.setItem("answers", JSON.stringify(new_answers))
      setAnswers(new_answers)
    }
  }

  return (
    <>
      {/* <Header /> */}
      <NavBar
        text={`${pendingSurveys?.length || "No"} assigned surveys — ${
          completedSurveys?.length || "None"
        } completed`}
        iconLeft="back"
        route="/capture-groups"
      />
      <DataLoadingContainer loading={isLoading}>
        {captureGroup && (
          <>
            <div className="mt-2 mb-2 p-4 text-gray-500 text-center">
              <span className="font-semibold text-xl text-gray-800">
                {captureGroup.subject.name}
              </span>
              {captureGroup.subject.business_name ? (
                <>
                  <br />
                  {captureGroup.subject.business_name}
                </>
              ) : null}
              <br />
              {captureGroup.subject.postcode}
              <br />
              {managers?.length ? <h2 className="font-bold">Managers:</h2> : ""}
              {managers?.map((m, i) => (
                <React.Fragment key={i}>
                  {m.firstname + " " + m.lastname} <br />
                  {m.email || ""} <br />
                  {m.telephone || ""}
                </React.Fragment>
              ))}
            </div>
          </>
        )}
        {(thisSurveys?.length > 0 &&
          (completedSurveys.length || stashedSurveys.length) && (
            <div
              className={`mx-auto m-4 mt-2 mb-8 p-8 border-2 ${
                online ? "border-green-600" : "border-orange-600"
              } rounded-xl w-4/5 md:w-1/2 lg:w-1/4 text-center`}
            >
              <div
                className={`font-semibold text-3xl ${
                  online ? "text-green-600" : "text-orange-600"
                }`}
              >
                Surveys Complete
              </div>
              <div className="mt-4 font-semibold text-xl text-gray-500">
                Now ready for submission:
                <ul className="font-normal text-lg text-gray-500">
                  {completedSurveys?.map((s) => (
                    <li>
                      <TranslationWrapper
                        translations={s.survey_schema_translations}
                      >
                        {s.schema.title}
                      </TranslationWrapper>
                    </li>
                  )) || null}
                  {stashedSurveys?.map((s) => (
                    <li>
                      (stashed){" "}
                      <TranslationWrapper
                        translations={s.survey_schema_translations}
                      >
                        {s.schema.title}
                      </TranslationWrapper>
                    </li>
                  )) || null}
                </ul>
              </div>
              {online ? (
                <button
                  className="mt-6 rounded-xl p-1 px-8 pr-4 text-white text-2xl font-semibold bg-primary"
                  onClick={() => setShowConfirm(true)}
                >
                  Submit
                  <FaAngleRight className="inline text-4xl" />
                </button>
              ) : (
                <p className="mt-4 font-semibold text-lg">
                  You are offline. Please connect to the internet to submit the
                  surveys
                </p>
              )}
            </div>
          )) ||
          null}
        <div className="mx-auto border-t-2 border-gray-500 md:border-t-0 md:w-1/2 lg:w-1/4 md:pt-5">
          {pendingSurveys?.map((survey) => (
            <SurveysListItem
              key={survey.unique_id}
              captureGroupId={props.match.params.captureGroupId}
              survey={survey}
              capturing={survey.capturing}
              submitted={survey.submitted}
              initializeNewSurvey={initializeNewSurvey}
            />
          ))}
        </div>
        {showConfirm && (
          <div className="inset-0 z-20 flex justify-center items-center fixed m-auto min-h-screen bg-black bg-opacity-50">
            <div className="my-6 px-6 mx-auto md:w-1/2 lg:w-1/4 flex flex-col flex-nowrap justify-center bg-white p-8 rounded-md">
              {submitted ? (
                <>
                  <div className="text-green-600 text-3xl font-semibold mt-1">
                    Submitting survey/s
                  </div>
                  <div className="text-gray-500 text-xl font-semibold mt-5">
                    Please wait . . .
                  </div>
                </>
              ) : (
                <>
                  {submitError ? (
                    <>
                      <div className="text-red-600 text-xl font-semibold mt-1">
                        There was an error submitting the survey/s.
                      </div>
                    </>
                  ) : (
                    <div className="text-gray-700 text-lg font-semibold flex flex-col items-center text-center">
                      OK to submit completed surveys?
                      <ul className="text-gray-500 text-base pt-2">
                        {completedSurveys.map((s) => (
                          <li>
                            <TranslationWrapper
                              translations={s.survey_schema_translations}
                            >
                              {s.schema.title}
                            </TranslationWrapper>
                          </li>
                        ))}
                        {stashedSurveys.map((s) => (
                          <li>
                            <TranslationWrapper
                              translations={s.survey_schema_translations}
                            >
                              {s.schema.title}
                            </TranslationWrapper>{" "}
                            (stashed)
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  <div className="flex justify-evenly pt-6">
                    <div className="">
                      <button
                        className="rounded-xl p-1 px-5 text-white text-2xl font-semibold bg-green-600"
                        onClick={() => submit()}
                      >
                        {submitError ? "Retry" : "OK"}
                      </button>
                    </div>
                    <div className="">
                      <button
                        className="rounded-xl p-1 px-5 text-white text-2xl font-semibold bg-gray-400"
                        onClick={() => {
                          setShowConfirm(false)
                          setSubmitError(false)
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {notSubmittedSurveys && (
          <>
            <div className="inset-0 z-20 flex justify-center items-center fixed m-auto min-h-screen bg-black bg-opacity-50">
              <div className="my-6 px-6 mx-auto md:w-1/2 lg:w-1/4 flex flex-col flex-nowrap justify-center bg-white p-8 rounded-md">
                <>
                  <div className="text-gray-700 text-lg font-semibold flex flex-col items-center text-center">
                    You do not have a valid training certificate to submit the
                    following surveys
                  </div>
                  <div className="text-gray-700 text-lg font-semibold flex flex-col items-center text-center">
                    <ul className="text-gray-500 text-base pt-2">
                      {notSubmittedSurveys.map((survey) => {
                        return (
                          <li key={survey.unique_id}>{survey.schema.title}</li>
                        )
                      })}
                    </ul>
                  </div>
                </>
                <div className="flex justify-evenly pt-6">
                  <div className="">
                    <button
                      className="rounded-xl p-1 px-5 text-white text-2xl font-semibold bg-green-600"
                      onClick={() => history.push("/capture-group")}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </DataLoadingContainer>
    </>
  )
}

import React, { useEffect, useState, useRef, useMemo } from "react"
import { useStore } from "react-context-hook"

import { useHistory } from "react-router-dom"
import { ModalWrapper } from "./modalWrapper"

const HasAnswerChangedModal = ({
  pushTo,
  setShowModal,
  setShowMajorNonConformance = false,
}) => {
  const [hasAnswersChangedMap, setHasAnswersChangedMap] = useStore(
    "hasAnswersChangedMap"
    // {}
  )

  const [hasAnswersChanged, setHasAnswersChanged] = useStore(
    "hasAnswersChanged"
    // false
  )

  const history = useHistory()

  useEffect(() => {
    console.log(hasAnswersChangedMap, hasAnswersChanged)
    if (
      hasAnswersChanged === false &&
      !Object.keys(hasAnswersChangedMap).length
    ) {
      console.log("INHERE")
      setShowMajorNonConformance && setShowMajorNonConformance(true)
      pushTo ? history.push(pushTo) : setShowModal(false)
    }
  }, [hasAnswersChangedMap, hasAnswersChanged])

  return (
    <ModalWrapper>
      <div className="text-gray-700 text-lg font-semibold flex flex-col items-center text-center">
        You have unsaved answers that will be lost when leaving this page.
      </div>
      <div className="flex justify-evenly pt-6 text-lg lg:text-2xl">
        <button
          disabled={false}
          className="rounded-xl p-1 px-5 mr-2 text-white  font-semibold bg-primary"
          onClick={() => {
            setHasAnswersChanged(false)
            setHasAnswersChangedMap({})
            console.log({ pushTo })
            // pushTo ? history.push(pushTo) : setShowModal(false)
          }}
        >
          Continue without saving
        </button>

        <button
          className="rounded-xl p-1 px-5 text-white font-semibold bg-gray-400"
          onClick={() => {
            setShowModal(false)
          }}
        >
          Cancel
        </button>
      </div>
    </ModalWrapper>
  )
}

export default HasAnswerChangedModal
// ;<ModalWrapper showModal={setShowAnswerChangeModal}>
//   <div className="text-gray-700 text-lg font-semibold flex flex-col items-center text-center">
//     You have unsaved answers that will be lost when leaving this page.
//   </div>
//   <div className="flex justify-evenly pt-6 text-lg lg:text-2xl">
//     <button
//       disabled={false}
//       className="rounded-xl p-1 px-5 mr-2 text-white  font-semibold bg-primary"
//       onClick={() => {
//         setHasAnswersChanged(false)
//         setHasAnswersChangedMap({})
//         history.push(props.route)
//       }}
//     >
//       Continue without saving
//     </button>

//     <button
//       className="rounded-xl p-1 px-5 text-white font-semibold bg-gray-400"
//       onClick={() => {
//         setShowAnswerChangeModal(false)
//       }}
//     >
//       Cancel
//     </button>
//   </div>
// </ModalWrapper>

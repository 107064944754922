import React, { Fragment, useEffect, useState } from "react"
import { useStore } from "react-context-hook"
import { useHistory } from "react-router"
import { Header } from "../components/header"
import { NavBar } from "../components/navbar"

import { NonConformantQuestion } from "./nonConformantQuestion"
import { checkSurveyCompleted } from "../../utils"
import { CommentBox } from "../components/questions/commentBox"
import TranslationWrapper from "../utils/translationWrapper"
import { UnsavedAnswerBanner } from "../components/banner/unsavedAnswerBanner"
import HasAnswerChangedModal from "../components/modal/hasAnswerChangedModal"

const NonConformantQuestionsComponent = ({
  surveys,
  ncqs,
  setHasAnswersChangedMap,
  hasAnswersChangedMap,
  refList,
  setRefList,
}) => {
  const [actionsCount, setActionsCount] = useState({})
  const [standardsCount, setStandardsCount] = useState({})

  useEffect(() => {
    actionsCounterHelper(ncqs)
    standardsCounterHelper(ncqs)
  }, [])

  const actionsCounterHelper = (ncqs) => {
    for (const [key, value] of Object.entries(ncqs)) {
      const counts = []
      Object.keys(value).forEach((ncq) => {
        let count = 0
        value[ncq].forEach((survey) => {
          count += survey.actions.standards.length
        })
        counts.push(count)
      })
      setActionsCount({ ...actionsCount, [key]: counts })
    }
  }

  const checkForMatchingElements = (a, b) => {
    let myBool = false
    a.forEach((aSpecies) => {
      if (myBool) return myBool
      b.forEach((bSpecies) => {
        if (aSpecies === bSpecies) {
          myBool = true
        }
      })
    })
    return myBool
  }

  const standardsCounterHelper = (ncqs) => {
    for (const [key, value] of Object.entries(ncqs)) {
      const counts = []
      Object.keys(value).forEach((ncq) => {
        const tracker = {}
        let count = 0
        value[ncq].forEach((survey) => {
          survey.actions.standards.forEach((standard) => {
            const standardsNumber = standard.number

            tracker[standardsNumber] = tracker[standardsNumber] || []

            const existingStandardsArray = tracker[standardsNumber]

            standard.species.forEach((spec) => {
              const temp = [...tracker[standardsNumber]]
              if (!temp.includes(spec)) {
                temp.push(spec)
              }
              tracker[standardsNumber] = temp
            })

            const newStandardsArray = tracker[standardsNumber]

            if (existingStandardsArray === []) {
              count++
            } else if (
              !checkForMatchingElements(
                existingStandardsArray,
                newStandardsArray
              )
            ) {
              count++
            }
            // check arrays against eachother if contain same item do nothing else add one
            // if original array was [] add 1
            // if all new add on
          })
        })
        counts.push(count)
      })
      setStandardsCount({ ...actionsCount, [key]: counts })
    }
  }

  return (
    <>
      {surveys?.map((survey) => {
        return (
          <Fragment key={survey.unique_id}>
            {ncqs[survey.unique_id] && (
              <>
                <div className="font-semibold mt-3 text-center text-xl text-gray-800">
                  <TranslationWrapper
                    translations={survey.survey_schema_translations}
                  >
                    {survey.schema.title}
                  </TranslationWrapper>
                </div>
                {Object.keys(ncqs[survey.unique_id]).map((section, idx) => (
                  <Fragment key={idx}>
                    <div className="font-semibold text-center text-lg text-gray-700">
                      <TranslationWrapper
                        translations={survey.survey_schema_translations}
                      >
                        {/* //!count goes here  */}
                        <div className="w-full flex flex-col  py-4">
                          <div>
                            Actions Raised:
                            {Object.keys(actionsCount).length &&
                              ` ${actionsCount[survey.unique_id][idx]}`}
                          </div>
                          <div>
                            Non-Conformances:
                            {Object.keys(standardsCount).length &&
                              ` ${standardsCount[survey.unique_id][idx]}`}
                          </div>
                        </div>

                        {survey.schema.sections[section].title}
                      </TranslationWrapper>
                    </div>
                    {ncqs[survey.unique_id][section].map((question, idx) => {
                      const question_number =
                        survey.schema.sections[section]?.questions
                          .filter((q) => q.type != "blank")
                          .findIndex((q) => q.id === question.id) + 1
                      return (
                        <>
                          <NonConformantQuestion
                            key={`${section}-${question_number}`}
                            survey={survey}
                            section={section}
                            question={question}
                            questionNumber={question_number}
                            setHasAnswersChangedMap={setHasAnswersChangedMap}
                            hasAnswersChangedMap={hasAnswersChangedMap}
                            refList={refList}
                            setRefList={setRefList}
                          />
                        </>
                      )
                    })}
                  </Fragment>
                ))}
              </>
            )}
          </Fragment>
        )
      }) || null}
    </>
  )
}

export const Review = (props) => {
  const [assessments, setAssessments] = useStore("assessments", null)
  const [assessment, setAssessment] = useStore(
    "assessment",
    assessments.find((ass) => ass.unique_id === props.match.params.assessmentId)
  )
  const [surveys, setSurveys] = useStore("surveys", null)
  const [nonConformantQuestions, setNonConformantQuestions] = useState([])

  const [answers, setAnswers] = useStore("answers", null)
  const [showMajorNonConformance, setShowMajorNonConformance] = useState(false)
  const [comment, setComment] = useState("")
  const [isNonConformant, setIsNonConformant] = useState(null)
  const [showAnswerChangeModal, setShowAnswerChangeModal] = useState(false)
  const [hasAnswersChangedMap, setHasAnswersChangedMap] = useStore(
    "hasAnswersChangedMap",
    {}
  )
  const [hasAnswersChanged, setHasAnswersChanged] = useStore(
    "hasAnswersChanged",
    false
  )
  const [refList, setRefList] = useStore("refList", {})

  const history = useHistory()

  useEffect(() => {
    if (hasAnswersChangedMap) {
      for (const [key, value] of Object.entries(hasAnswersChangedMap)) {
        if (value.answer || value.comment) {
          setHasAnswersChanged(true)
          break
        }
        setHasAnswersChanged(false)
      }
    }
  }, [hasAnswersChangedMap])

  useEffect(() => {
    if (surveys) {
      const all_non_conformants = {}
      assessment.surveys
        .filter(
          (s) => !s.submitted
          // assessment.surveys.some((as) => as.unique_id === s.unique_id)
        )
        .forEach((survey) => {
          if (
            checkSurveyCompleted(
              surveys.find((s) => s.unique_id === survey.unique_id),
              answers[survey?.unique_id],
              answers,
              assessment.subject.species
            )
          ) {
            answers[survey.unique_id].schema.sections.forEach(
              (section, idx) => {
                const non_conformants = []
                section.questions.forEach((q) => {
                  if (
                    // q.conformance &&
                    // JSON.parse(q.conformance.conformant) === false
                    q.actions
                  ) {
                    non_conformants.push(q)
                  }
                  if (non_conformants.length >= 1)
                    all_non_conformants[survey.unique_id] = {
                      ...all_non_conformants[survey.unique_id],
                      [idx]: non_conformants,
                    }
                })
              }
            )
          }
        })
      setNonConformantQuestions(all_non_conformants)
    }
  }, [surveys])

  const handleMajorNonconformance = (answer) => {
    // update major_non_conformance = answer (bool)
    // This should be put in answers where it can be received on api
    // on submit

    assessment.surveys.forEach((survey) => {
      if (survey.qualifying_survey === true) {
        if (answers[survey.unique_id]) {
          answers[survey.unique_id].schema.major_non_conformance = answer
          answers[survey.unique_id].schema.major_non_conformance_comment =
            comment
        }
      }
    })
    localStorage.setItem("answers", JSON.stringify(answers))
    history.push(`/assessment/${props.match.params.assessmentId}/sign-off`)
  }

  useEffect(() => {
    console.log(hasAnswersChanged, hasAnswersChangedMap)
  }, [showMajorNonConformance])

  if (!showMajorNonConformance)
    return (
      <>
        {/* <Header /> */}
        <NavBar
          text={`Reviewing Non-Conformances`}
          iconLeft="back"
          route={`/assessment/${props.match.params.assessmentId}`}
        />

        <UnsavedAnswerBanner />

        {assessment && (
          <>
            <div className="mt-2 mb-2 p-4 text-gray-500 text-center">
              <span className="font-semibold text-xl text-gray-800">
                {assessment.subject.name}
              </span>
              <br />
              {assessment.subject.postcode}
              <br />
            </div>
          </>
        )}
        {Object.keys(nonConformantQuestions).length >= 1 ? (
          <div className="mx-auto border-t-2 border-gray-500 md:border-t-0 md:w-1/2 lg:w-1/4 md:pt-5">
            <NonConformantQuestionsComponent
              surveys={surveys}
              ncqs={nonConformantQuestions}
              setHasAnswersChangedMap={setHasAnswersChangedMap}
              hasAnswersChangedMap={hasAnswersChangedMap}
              refList={refList}
              setRefList={setRefList}
            />
          </div>
        ) : (
          <div className="mt-2 mb-2 p-4 text-gray-500 text-center">
            No non-conformances to review
          </div>
        )}

        <div className="my-6 w-full text-center">
          <button
            onClick={() => {
              if (hasAnswersChanged) {
                setShowAnswerChangeModal(true)
              } else {
                if (assessment.surveys.some((s) => s.qualifying_survey))
                  setShowMajorNonConformance(true)
                else history.push("sign-off")
              }
            }}
            className="mx-auto rounded-xl p-2 px-3 text-white text-2xl font-semibold bg-green-600"
          >
            Sign off and submit
          </button>
        </div>

        {showAnswerChangeModal && (
          <HasAnswerChangedModal
            pushTo={
              assessment.surveys.some((s) => s.qualifying_survey)
                ? null
                : "sign-off"
            }
            setShowModal={setShowAnswerChangeModal}
            setShowMajorNonConformance={setShowMajorNonConformance}
          />
        )}
      </>
    )
  else
    return (
      <>
        {/* <Header /> */}
        <NavBar
          text={`Reviewing Non-Conformances`}
          iconLeft="back"
          route={`/assessment/${props.match.params.assessmentId}`}
        />
        {assessment && (
          <>
            <div className="mt-2 mb-2 p-4 text-gray-500 text-center">
              <span className="font-semibold text-xl text-gray-800">
                {assessment.subject.name}
              </span>
              <br />
              {assessment.subject.postcode}
              <br />
            </div>
          </>
        )}

        <div className="flex flex-col gap-2 m-auto max-w-lg">
          <p className="text-center mt-2 px-2 text-lg">
            Are you as a Select Farm Assessor happy to approve the farm
            assessed. provided any minor non-conformances raised (if any) are
            closed out within 28 days?
          </p>
          <p className="text-center mb-2 px-2 text-md text-gray-500">
            Answering this question with 'No' results in a major non-conformance
            which will trigger review of this farms supply by its processor.
          </p>
          <div className="flex gap-2 mx-auto mt-2">
            <button
              // onClick={() => handleMajorNonconformance(false)}
              onClick={() => setIsNonConformant(false)}
              className={`rounded-xl py-1 px-3 text-white text-2xl font-semibold bg-green-600 border-4 ${
                isNonConformant === false
                  ? "border-green-900"
                  : "border-transparent"
              }`}
            >
              Yes
            </button>

            <button
              // disabled={!hasComment}
              onClick={() => setIsNonConformant(true)}
              className={`rounded-xl py-1 px-3 text-white text-2xl font-semibold bg-green-600 border-4 ${
                isNonConformant === true
                  ? "border-green-900"
                  : "border-transparent"
              }`}
            >
              No
            </button>
          </div>
          {isNonConformant !== null && (
            <>
              <div className="w-full px-2">
                <CommentBox
                  placeholder={
                    "Comments are requires for all major non-conformances"
                  }
                  setComment={setComment}
                  comment={comment}
                  ignoreGlobalChangeMonitoring={true}
                />
              </div>
              <div className="my-6 w-full text-center">
                <button
                  onClick={() => handleMajorNonconformance(isNonConformant)}
                  className={`rounded-xl p-2 px-3 text-white text-2xl font-semibold ${
                    !comment.length && isNonConformant
                      ? "bg-gray-400"
                      : "bg-green-600"
                  } `}
                  disabled={!comment.length && isNonConformant}
                >
                  Continue
                </button>
              </div>
            </>
          )}
        </div>
      </>
    )
}

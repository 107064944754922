import React, { useEffect, useState } from "react"
import { RiCloseCircleFill } from "react-icons/ri"
import SlapMark from "./slapmark"

const ActionPopup = ({ message, confirmCallback, setConfirmPopup }) => {
  return (
    <div className="fixed w-screen top-0 z-30 left-0 h-screen mx-auto bg-gray-300 bg-opacity-70 grid place-items-center">
      <div className="md:w-1/2 lg:w-1/4 bg-white border-2 rounded border-black p-5">
        <p className="text-center text-lg">{message}</p>
        <div className="flex flex-row justify-between mt-5">
          <button
            className="w-32 rounded-xl border-2 border-red-600 bg-red-600 text-white p-1 px-2"
            onClick={() => setConfirmPopup(null)}
          >
            {" "}
            Cancel{" "}
          </button>
          <button
            className="w-32 rounded-xl border-2 border-white bg-green-600 text-white p-1 px-2"
            onClick={() => {
              confirmCallback()
              setConfirmPopup(null)
            }}
          >
            {" "}
            Confirm{" "}
          </button>
        </div>
      </div>
    </div>
  )
}

const InputField = ({
  name,
  required,
  label,
  onChange: change,
  defaultValue,
}) => {
  return (
    <div className="flex flex-col">
      <label className="font-md text-gray-600">{label}</label>
      <input
        name={name}
        required={required}
        className="border border-gray-400 rounded p-2 w-full"
        defaultValue={defaultValue}
        onChange={(e) => change(e)}
      />
    </div>
  )
}

const AddLinkedHolding = ({
  index,
  linkedHolding,
  setLinkedHolding,
  assessment,
  slapMarksMap,
  setSlapMarksMap,
  hasPork,
}) => {
  const [confirmPopup, setConfirmPopup] = useState(null)

  const linkedHoldingHelper = new LinkedHoldingHelper(index)

  const updateLinkedHolding = (event) => {
    linkedHoldingHelper.updateLinkedHolding(
      event,
      linkedHolding,
      setLinkedHolding,
      assessment
    )
  }

  const removeLinkedHolding = () => {
    linkedHoldingHelper.removeLinkedHolding(linkedHolding, setLinkedHolding)
  }

  // useEffect(()=>{updateLinkedHolding()},[slapMarksMap])

  return (
    <>
      <div
        className={`border-2 rounded border-black p-5 
         ${
           Object.keys(linkedHolding).length - 1 === parseInt(index)
             ? "mb-0"
             : "mb-5"
         }
        `}
      >
        <div className={`relative col-span-2 h-auto `}>
          <button
            className="absolute bg-white rounded-full"
            style={{
              top: "-35px",
              right: "-37px",
            }}
          >
            <RiCloseCircleFill
              size="36px"
              className="text-red-600"
              onClick={(e) => {
                e.preventDefault()
                setConfirmPopup({
                  message:
                    "Are you sure you want to delete this Linked Holding?",
                  confirmCallback: () =>
                    removeLinkedHolding(linkedHolding, setLinkedHolding),
                })
              }}
            />
          </button>
        </div>
        <InputField
          label={"Site Name*"}
          name={"siteName"}
          onChange={updateLinkedHolding}
          defaultValue={linkedHolding[index].siteName || ""}
          required
        />
        <InputField
          label={"Company Name*"}
          name={"companyName"}
          onChange={updateLinkedHolding}
          defaultValue={linkedHolding[index].companyName || ""}
          required
        />
        <InputField
          label={"Address 1*"}
          name={"address_1"}
          onChange={updateLinkedHolding}
          defaultValue={linkedHolding[index].address_1 || ""}
          required
        />
        <InputField
          label={"Address 2"}
          name={"address_2"}
          onChange={updateLinkedHolding}
          defaultValue={linkedHolding[index].address_2 || ""}
          required={false}
        />
        <InputField
          label={"City*"}
          name={"city"}
          onChange={updateLinkedHolding}
          defaultValue={linkedHolding[index].city || ""}
          required
        />
        <InputField
          label={"County*"}
          name={"county"}
          onChange={updateLinkedHolding}
          defaultValue={linkedHolding[index].county || ""}
          required
        />
        <InputField
          label={"Postcode*"}
          name={"postcode"}
          onChange={updateLinkedHolding}
          defaultValue={linkedHolding[index].postcode || ""}
          required
        />
        <InputField
          label={"Country*"}
          name={"country"}
          onChange={updateLinkedHolding}
          defaultValue={linkedHolding[index].country || ""}
          required
        />
        {hasPork && (
          <div className="flex flex-col">
            <div className="mt-4">
              <SlapMark
                index={index}
                slapMarksMap={slapMarksMap}
                setSlapMarksMap={setSlapMarksMap}
              />
            </div>
          </div>
        )}
      </div>
      {confirmPopup !== null && (
        <ActionPopup
          message={confirmPopup.message}
          confirmCallback={confirmPopup.confirmCallback}
          setConfirmPopup={setConfirmPopup}
        />
      )}
    </>
  )
}

export default AddLinkedHolding

class LinkedHoldingHelper {
  constructor(index) {
    this.index = index
  }

  updateLinkedHolding = (
    event,
    linkedHolding,
    setLinkedHolding,
    assessment
  ) => {
    const { value, name } = event.target

    const temp = linkedHolding
    temp[this.index] = {
      ...temp[this.index],
      [name]: value,
      farm_unique_id: assessment.subject.unique_id,
    }
    setLinkedHolding({ ...temp })
  }

  removeLinkedHolding(linkedHolding, setLinkedHolding) {
    const temp = linkedHolding
    temp[this.index] = false

    setLinkedHolding({ ...temp })
  }
}

import React, { useEffect, useRef, useState } from "react"
import { useStoreValue } from "react-context-hook"
import { QuestionStatus } from "../saveQuestion"
import { translateSurveyText } from "../../../../utils/translate"
import { HasAnswerChangedHook } from "../helpers/hasAnswerChangedHook"

export const MultiselectQuestion = (props) => {
  const [answer, setAnswer] = useState(props.answer || [])

  const [saved, setSaved] = useState(false)
  const [valid, setValid] = useState(true)
  const [errorMessage, setErrorMessage] = useState(null)
  const [saveButtonText, setSaveButtonText] = useState("Save")
  const [saveButtonColour, setSaveButtonColour] = useState("bg-primary")

  const lang = useStoreValue("language")
  const refKey = props.changeAnswerKey

  useEffect(() => {
    if (props.answer) setSaved(true)
  }, [])

  HasAnswerChangedHook(answer, refKey, props.questionRef, props.question.id)

  const saveAnswer = () => {
    if (answer.length === 0) {
      setValid(false)
      setErrorMessage("You must select one.")
      setSaveButtonColour("bg-red-600")
      setSaveButtonText("Error!")

      props.removeFromAnswers()
      setSaved(false)
    } else {
      setValid(true)
      setErrorMessage(null)
      setSaveButtonColour("bg-green-600")
      setSaveButtonText("Saved!")

      props.saveToAnswers(answer)
      setSaved(true)
    }

    setTimeout(() => {
      setSaveButtonColour("bg-primary")
      setSaveButtonText("Save")
    }, 1000)
  }

  const onSelectChange = (e) => {
    const values = [...e.target.selectedOptions].map((opt) => opt.value)
    setAnswer(values)
    setSaved(false)
  }

  const onCheckboxChange = (e) => {
    let newAnswer = [...answer]
    if (e.target.checked) {
      newAnswer.push(e.target.value)
    } else {
      if (newAnswer.includes(e.target.value)) {
        newAnswer = newAnswer.filter((box) => box !== e.target.value)
      }
    }
    setAnswer(newAnswer)
    setSaved(false)
  }

  return (
    <div>
      {props.question.properties.visual_type === "dropdown" && (
        <select
          className={`w-full p-3 outline-none text-base border-2 border-${
            valid ? "gray" : "red"
          }-300 rounded-lg appearance-none focus:border-2 focus:border-yellow-500 focus:rounded`}
          value={answer}
          onChange={(e) => onSelectChange(e)}
          size={props.question.properties.options.length}
          multiple
        >
          {props.question.properties.options.map((option) => (
            <option key={option} value={option}>
              {translateSurveyText(
                lang,
                option,
                props.survey.survey_schema_translations
              )}
            </option>
          ))}
        </select>
      )}
      {props.question.properties.visual_type === "checkbox" && (
        <div className="grid grid-cols-2 gap-1 text-left text-sm">
          {props.question.properties.options.map((option) => (
            <div key={option} className="p-2">
              <label>
                <input
                  type="checkbox"
                  className="relative top-0.5"
                  value={option}
                  onChange={(e) => onCheckboxChange(e)}
                  checked={answer.includes(option)}
                />
                &nbsp;
                {translateSurveyText(
                  lang,
                  option,
                  props.survey.survey_schema_translations
                )}
              </label>
            </div>
          ))}
        </div>
      )}
      <QuestionStatus
        question={props.question}
        answer={answer}
        saveAnswer={saveAnswer}
        saved={saved}
        saveButtonColour={saveButtonColour}
        setSaveButtonColour={setSaveButtonColour}
        saveButtonText={saveButtonText}
        setSaveButtonText={setSaveButtonText}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        comment={props.comment}
        saveComment={props.saveComment}
        saveStandard={props.saveStandard}
        conformance={props.conformance}
        saveConformance={props.saveConformance}
        changeAnswerKey={props.changeAnswerKey}
        questionRef={props.questionRef}
      />
    </div>
  )
}

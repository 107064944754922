import React from "react"
import { useHistory } from "react-router-dom"
import { FaAngleLeft } from "react-icons/fa"

export const BackIcon = ({ route = null, setShowAnswerChangeModal = null }) => {
  const history = useHistory()

  const handleClick = () => {
    if (route) {
      const prevPath = history.location?.state?.prevPath || null
      const goTo = prevPath ? prevPath : route

      history.push(goTo)
    }
    if (setShowAnswerChangeModal) {
      setShowAnswerChangeModal(true)
    }
  }

  return (
    <div className="w-10 h-full pt-1">
      <FaAngleLeft
        className="text-4xl mx-auto md:cursor-pointer"
        onClick={handleClick}
      />
    </div>
  )
}

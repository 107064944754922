import React, { useEffect, useState } from "react"
import { useStore, useStoreValue } from "react-context-hook"
import { evaluateQuestionDisplayConditions } from "../../utils"
import { translateSurveyText } from "../../utils/translate"
import { Header } from "../components/header"
import { NavBar } from "../components/navbar"
import { SectionListItem } from "../components/sectionListItem"
import TranslationWrapper from "../utils/translationWrapper"

export const Survey = (props) => {
  const language = useStoreValue("language")
  const [captureGroups, setCaptureGroups] = useStore("captureGroups", null)
  const [captureGroup, setCaptureGroup] = useStore(
    "captureGroup",
    captureGroups.find(
      (cg) => cg.unique_id === props.match.params.captureGroupId
    )
  )
  const [assessments, setAssessments] = useStore("assessments", null)
  const [assessment, setAssessment] = useStore(
    "assessment",
    assessments.find((ass) => ass.unique_id === props.match.params.assessmentId)
  )
  const [surveys, setSurveys] = useStore("surveys", null)
  const [survey, setSurvey] = useStore("survey", null)
  const [sections, setSections] = useState([])
  const [answers, setAnswers] = useStore("answers", null)
  const [pendingSections, setPendingSections] = useState(null)

  useEffect(() => {
    if (surveys) {
      const thisSurvey = surveys.filter(
        (survey) => survey.unique_id == props.match.params.surveyId
      )[0]
      setSurvey(thisSurvey)
      const sections = thisSurvey.schema.sections
      setSections(sections)
    }
  }, [surveys])

  useEffect(() => {
    if (answers && surveys && sections) {
      let sectionsCompletedCount = 0
      if (answers[props.match.params.surveyId]) {
        answers[props.match.params.surveyId].schema.sections.forEach(
          (section, idx) => {
            let answeredCount = 0
            section.questions
              .filter((q) => q.required && q.type !== "blank")
              .forEach((q) => {
                if (
                  q.hasOwnProperty("answer") ||
                  !evaluateQuestionDisplayConditions(
                    q,
                    answers,
                    surveys.filter(
                      (survey) =>
                        survey.unique_id == props.match.params.surveyId
                    )[0],
                    assessment?.subject.species ||
                      captureGroup?.subject.species,
                    idx
                  )
                ) {
                  answeredCount++
                }
              })
            if (
              answeredCount >=
              section.questions.filter((q) => q.required && q.type !== "blank")
                .length
            ) {
              sectionsCompletedCount++
            }
          }
        )
        setPendingSections(sections.length - sectionsCompletedCount)
      }
    }
  }, [answers, surveys, sections])

  return (
    <>
      {/* <Header /> */}
      <NavBar
        text={
          sections.length > 0 && pendingSections !== null
            ? pendingSections === 0
              ? `All sections completed`
              : `${pendingSections || ""} sections pending`
            : `All sections pending`
        }
        iconLeft="back"
        route={`/${survey?.capturing ? "capture-group" : "assessment"}/${
          props.match.params.assessmentId || props.match.params.captureGroupId
        }`}
      />
      {survey && (
        <div className="mt-2 mb-2 p-4 text-gray-500 text-center">
          <div className="font-semibold text-xl text-gray-800">
            <TranslationWrapper
              translations={survey.survey_schema_translations}
            >
              {survey.schema.title}
            </TranslationWrapper>
          </div>
          <div
            className="text-sm"
            dangerouslySetInnerHTML={{
              __html: translateSurveyText(
                language,
                survey.schema.introduction_text,
                survey.survey_schema_translations
              ),
            }}
          ></div>
        </div>
      )}
      <div className="mx-auto border-t-2 border-gray-500 md:border-t-0 md:w-1/2 lg:w-1/4 md:pt-5">
        {sections.map((section, index) => (
          <SectionListItem
            key={index}
            id={index}
            section={section}
            survey={survey}
            params={props.match.params}
          />
        ))}
      </div>
    </>
  )
}

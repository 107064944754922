import React, { useEffect, useState } from "react"
import { useStore, useStoreValue } from "react-context-hook"
import { Header } from "../components/header"
import { NavBar } from "../components/navbar"

import { useLiveQuery } from "dexie-react-hooks"
import { db } from "../../utils/indexedDb"
import { SignatureCanvas } from "../components/signature/signatureCanvas"
import { useHistory, useParams, useLocation } from "react-router"
import { checkSurveyCompleted, useOnlineStatus } from "../../utils"
import { getAssessments } from "../../Routes"
import TranslationsWrapper from "../utils/translationWrapper"

export const SignOff = (props) => {
  const [account, setAccount] = useStore("account", null)
  const [assessments, setAssessments] = useStore("assessments", null)
  const [assessment, setAssessment] = useState(null)
  const [surveys, setSurveys] = useStore("surveys", null)
  const [nonConformantQuestions, setNonConformantQuestions] = useState([])

  const [answers, setAnswers] = useStore("answers", null)
  const [showConfirm, setShowConfirm] = useState(false)
  const [error, setError] = useState(null)
  const [submitted, setSubmitted] = useState(false)
  const [submitError, setSubmitError] = useState(false)

  const [assessorSig, setAssessorSig] = useState(null)
  const [farmerSig, setFarmerSig] = useState(null)
  const [farmerName, setFarmerName] = useState(null)
  const [message, setMessage] = useState("")
  const [completedBy, setCompletedBy] = useState(null)

  const [surveysToSubmit, setSurveysToSubmit] = useState([])
  const [notSubmittedSurveys, setNotSubmittedSurveys] = useState(null)

  const allFiles = useLiveQuery(() => db.items.toArray(), [])

  const history = useHistory()
  const location = useLocation()
  const params = useParams()

  const online = useOnlineStatus()

  const saveSurveysSignoff = () => {
    let surveys = []
    console.log("call 1")
    surveysToSubmit.forEach((survey) => {
      answers[survey.unique_id].schema.completed_by = account
      answers[survey.unique_id].schema.assessor_signature = assessorSig
      answers[survey.unique_id].schema.farmer_signature = farmerSig
      answers[survey.unique_id].schema.farmer_name = farmerName
      if (survey.qualifying_survey) {
        let now = new Date()
        let due = new Date(new Date().setDate(now.getDate() + 28))
        answers[survey.unique_id].schema.submission_timestamp =
          now.toISOString()
        answers[survey.unique_id].schema.evidence_due_timestamp =
          due.toISOString()
        answers[survey.unique_id].qualifying_survey = true
      }
      surveys.push(answers[survey.unique_id])
    })
    localStorage.setItem("answers", JSON.stringify(answers))
    return surveys
  }

  const handleSaveSignoff = () => {
    if (!farmerSig || !assessorSig || !farmerName) {
      setError(1)
      return
    }
    setError(null)
    surveysToSubmit.forEach((survey) => {
      answers[survey.unique_id].schema.completed_by = account
      answers[survey.unique_id].schema.assessor_signature = assessorSig
      answers[survey.unique_id].schema.farmer_signature = farmerSig
      answers[survey.unique_id].schema.farmer_name = farmerName
      if (survey.qualifying_survey) {
        let now = new Date()
        let due = new Date(new Date().setDate(now.getDate() + 28))
        answers[survey.unique_id].schema.submission_timestamp =
          now.toISOString()
        answers[survey.unique_id].schema.evidence_due_timestamp =
          due.toISOString()
        answers[survey.unique_id].qualifying_survey = true
      }
    })
    setAnswers({ ...answers })
    localStorage.setItem("answers", JSON.stringify(answers))
    history.push(`/assessment/${props.match.params.assessmentId}`)
  }

  const handleSaveSigToLocalStorage = () => {
    if (assessorSig && farmerSig && farmerName) {
      surveysToSubmit.forEach((survey) => {
        answers[survey.unique_id].assessor = account
        answers[survey.unique_id].schema.completed_by = account
        answers[survey.unique_id].sign_off_date = getCurrentDateTime()
        answers[survey.unique_id].schema.assessor_signature = assessorSig
        answers[survey.unique_id].schema.farmer_signature = farmerSig
        answers[survey.unique_id].schema.farmer_name = farmerName
      })

      setAnswers({ ...answers })
      localStorage.setItem("answers", JSON.stringify(answers))
    }
  }

  const isNonConformantHandler = () => {
    let isNonConformant = "no_non_conformance"
    const currentAssessment = assessments.find(
      (ass) => ass.unique_id === props.match.params.assessmentId
    )
    currentAssessment?.surveys.forEach((survey) => {
      if (answers[survey.unique_id]) {
        if (answers[survey.unique_id]?.schema.major_non_conformance === true) {
          return (isNonConformant = "major_non_conformance")
        }

        answers[survey.unique_id]?.schema.sections.forEach((section) => {
          section.questions.forEach((q) => {
            if (
              q.conformance &&
              JSON.parse(q?.conformance?.conformant) === false
            ) {
              return (isNonConformant = "minor_non_conformance")
            }
          })
        })
      }
    })
    return isNonConformant
  }

  useEffect(() => {
    handleSaveSigToLocalStorage()
  }, [assessorSig, farmerSig, farmerName, surveysToSubmit])

  useEffect(() => {
    switch (isNonConformantHandler()) {
      case "major_non_conformance":
        setMessage(
          "Please note as a result of this assessment your select farm approval is pending review. A representative of your processor will be in contact."
        )
        break
      case "minor_non_conformance":
        setMessage(
          "Please note as a result of your selected farm assessment you are now provisionally approved, you will have '28 days' to close out any actions raised."
        )
        break
      case "no_non_conformance":
        setMessage(
          "Please note as a result of of your assessment you are now an approved M&S Select Farm, there are no further actions required at this stage."
        )
        break
      default:
        break
    }
  }, [assessments])

  useEffect(() => {
    if (assessments) {
      const thisAssessment = assessments.filter(
        (assmnt) => assmnt.unique_id == props.match.params.assessmentId
      )[0]
      setAssessment(thisAssessment)
      let surveys = []
      thisAssessment?.surveys.forEach((survey) => {
        // if (
        //   answers[survey.unique_id]?.schema.assessor_signature &&
        //   answers[survey.unique_id]?.schema.farmer_signature
        // )
        //   return
        // Only submit those surveys that are completed and that haven't been submitted already
        if (
          checkSurveyCompleted(
            survey,
            answers[survey.unique_id],
            answers,
            thisAssessment.subject.species
          ) &&
          !survey.submitted
        ) {
          surveys.push(answers[survey.unique_id])
        }
      })
      setSurveysToSubmit(surveys)
    }
  }, [assessments])

  useEffect(() => {
    if (surveys && assessment) {
      const all_non_conformants = {}
      assessment?.surveys
        .filter(
          (s) => !s.submitted
          // assessment.surveys.some((as) => as.unique_id === s.unique_id)
        )
        .forEach((survey) => {
          if (
            checkSurveyCompleted(
              surveys.find((s) => s.unique_id === survey.unique_id),
              answers[survey?.unique_id],
              answers,
              assessment.subject.species
            )
          ) {
            answers[survey.unique_id].schema.sections.forEach(
              (section, idx) => {
                const non_conformants = []
                section.questions.forEach((q) => {
                  if (
                    q.conformance &&
                    JSON.parse(q.conformance.conformant) === false
                  ) {
                    non_conformants.push(q)
                  }
                  if (non_conformants.length >= 1)
                    all_non_conformants[survey.unique_id] = {
                      ...all_non_conformants[survey.unique_id],
                      [idx]: non_conformants,
                    }
                })
              }
            )
          }
        })
      setNonConformantQuestions(all_non_conformants)
    }
  }, [surveys, assessment])

  //#region submit
  const submitFiles = () => {
    return Promise.all(
      allFiles.map((file) => {
        const formData = new FormData()
        formData.append(file.unique_id, file.data)
        return fetch(
          `${process.env.REACT_APP_API_HOST}/${account.tenant.identifier}/assess/submitFile/${file.unique_id}`,
          {
            method: "POST",
            body: formData,
            credentials: "include",
          }
        )
      })
    ).catch((err) => {
      console.log(err)
    })
  }

  const submitSurveys = () => {
    setSubmitted(true)
    const assessmentSubject = assessment.subject
    delete assessmentSubject["linkedHoldings"]

    const data = saveSurveysSignoff()
    return fetch(
      `${process.env.REACT_APP_API_HOST}/${account.tenant.identifier}/assess/${assessment.unique_id}/submit`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          surveys: data,
          // only include profile if it hasn't been submitted already, fixes duplication error
          subjectProfile: assessment.subject?.submitted
            ? null
            : assessmentSubject || null,
        }),
        credentials: "include",
      }
    ).then((res) => res.json())
  }

  const submitLinkedHoldings = async () => {
    const { linkedHoldings: _LH } = JSON.parse(
      localStorage.getItem(`profile_verified_${params.assessmentId}`)
    )
    return fetch(
      `${process.env.REACT_APP_API_HOST}/${account.tenant.identifier}/linkedHoldings`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          linkedHoldings: _LH,
        }),
        credentials: "include",
      }
    )
      .then((res) => res.json())
      .catch((err) => console.log(err))
  }

  const submit = () => {
    setSubmitError(false)
    Promise.all([submitSurveys(), submitFiles()])
      .then(([resS, resF]) => {
        if (resS.status === 200) {
          setSubmitted(true)
          setShowConfirm(false)
          getAssessments(
            account.tenant.identifier,
            account.role,
            account.unique_id
          ).then(async (res) => {
            await submitLinkedHoldings().then((resLH) => console.log(resLH))

            let remainingAssessments = res.filter((a) =>
              a.surveys.some((s) => !s.submitted)
            )
            let submittedAssessment = remainingAssessments.find(
              (a) => a.unique_id === assessment.unique_id
            )
            console.log({ remainingAssessments, submittedAssessment })
            if (submittedAssessment?.subject) {
              submittedAssessment.subject.submitted = true
              localStorage.setItem(
                `profile_verified_${submittedAssessment.unique_id}`,
                JSON.stringify(submittedAssessment.subject)
              )
            } else if (!submittedAssessment) {
              localStorage.removeItem(
                `profile_verified_${assessment.unique_id}`
              )
            }
            setAssessments(remainingAssessments)

            if (resS?.data.notSubmittedSurveys.length > 0) {
              setNotSubmittedSurveys(resS.data.notSubmittedSurveys)
            } else {
              history.push("/assessments")
            }
          })
        } else {
          setSubmitted(false)
          setSubmitError(true)
          console.log("Error submitting assessment surveys...")
        }
      })
      .catch((error) => {
        if (!window.navigator.onLine) {
          console.log("Scheduled for later")
          setSubmitted(true)
          setShowConfirm(false)
        } else {
          console.log("Error submitting assessment surveys...")
        }
      })
  }
  //#endregion

  const handlePDFCreation = () => {
    const arrayOfIds = []
    const notSubmittedSurvey = assessment.surveys.filter((s) => !s.submitted)
    notSubmittedSurvey.forEach((s) => {
      arrayOfIds.push(s.unique_id)
    })
    window.open(
      `${location.pathname}/pdf?notCompletedSurveysIds=${arrayOfIds}`,
      "_blank"
    )
  }

  const handleSubmit = () => {
    if (farmerSig && assessorSig && farmerName) {
      setShowConfirm(true)
      setError(null)
      return
    }
    setError(1)
  }

  const getCurrentDateTime = () => {
    let newDate = new Date()
    return newDate.toLocaleString("en-GB")
  }

  return (
    <>
      {/* <Header /> */}
      <NavBar
        text={`Assessment Sign-off`}
        iconLeft="back"
        route={`/assessment/${props.match.params.assessmentId}/review`}
      />
      {assessment && (
        <>
          <div className="mt-2 mb-2 p-2 text-gray-500 text-center">
            <span className="font-semibold text-xl text-gray-800">
              {assessment.subject.name}
            </span>
            <br />
            <span>{assessment.subject.postcode}</span>
            <br />
            <span>
              <span className="font-semibold">Assessor:</span>{" "}
              {account.firstname} {account.lastname}
            </span>
            <br />
            <span>
              <span className="font-semibold">Date of Assessment:</span>{" "}
              {getCurrentDateTime()}
            </span>
          </div>
        </>
      )}
      <div className="  mb-1 p-2 mx-auto border-gray-500 md:border-t-0 md:w-1/2 lg:w-1/4 md:pt-5 ">
        <p className="text-left">{message}</p>
      </div>
      <div className="mb-1 p-2 mx-auto border-gray-500 md:border-t-0 md:w-1/2 lg:w-1/4 md:pt-5">
        <div className="text-xl font-semibold mt-1">Assessor Signature:</div>
        <SignatureCanvas
          onSave={(b64) => {
            setAssessorSig(b64)
          }}
        />
        <div className="text-xl font-semibold mt-1">Farmer Signature:</div>
        <SignatureCanvas
          onSave={(b64) => {
            setFarmerSig(b64)
          }}
        />
        <input
          type="text"
          placeholder="Farmer's name"
          onChange={(e) => {
            setFarmerName(e.target.value)
          }}
          className={`w-full border-2 border-gray-400 focus:border-yellow-500 rounded-lg p-2 text-left mt-2`}
          autoComplete="off"
        />
      </div>
      {error ? (
        <div className="w-full text-center text-red-600 mb-3">
          All fields are required
        </div>
      ) : null}
      <div className="w-full text-center mb-3">
        {online ? (
          <div className="max-w-sm mx-auto">
            <button
              onClick={handleSubmit}
              disabled={!(farmerSig && assessorSig && farmerName)}
              className={`rounded-xl p-2 px-3 text-white text-2xl font-semibold mr-2 ${
                farmerSig && assessorSig && farmerName
                  ? "bg-green-600"
                  : "bg-gray-400"
              }`}
            >
              Submit
            </button>

            <button
              onClick={handlePDFCreation}
              disabled={!(farmerSig && assessorSig && farmerName)}
              className={`rounded-xl p-2 px-3 text-white text-2xl font-semibold ${
                farmerSig && assessorSig && farmerName
                  ? "bg-green-600"
                  : "bg-gray-400"
              }`}
            >
              View report
            </button>
          </div>
        ) : (
          <div>
            <p className="mt-4 font-semibold text-lg">
              You are offline. Click to save sign off information and submit
              when online.
            </p>
            <button
              onClick={handleSaveSignoff}
              className={`mx-auto rounded-xl mt-4 p-2 px-3 text-white text-2xl font-semibold ${
                farmerSig && assessorSig && farmerName
                  ? "bg-green-600"
                  : "bg-gray-400"
              }`}
            >
              Save
            </button>
          </div>
        )}
      </div>
      {showConfirm && (
        <div className="inset-0 z-20 flex justify-center items-center fixed m-auto min-h-screen bg-black bg-opacity-50">
          <div className="my-6 px-6 mx-auto md:w-1/2 lg:w-1/4 flex flex-col flex-nowrap justify-center bg-white p-8 rounded-md">
            {submitted ? (
              <>
                <div className="text-green-600 text-3xl font-semibold mt-1">
                  Submitting assessment
                </div>
                <div className="text-gray-500 text-xl font-semibold mt-5">
                  Please wait . . .
                </div>
              </>
            ) : (
              <>
                {submitError ? (
                  <>
                    <div className="text-red-600 text-xl font-semibold mt-1">
                      There was an error submitting the assessment.
                    </div>
                  </>
                ) : (
                  <div className="text-gray-700 text-lg font-semibold flex flex-col items-center text-center">
                    OK to submit the following surveys?
                    <ul className="text-gray-500 text-base pt-2">
                      {surveysToSubmit?.length
                        ? surveysToSubmit.map((s) => (
                            <li key={s.unique_id}>
                              <TranslationsWrapper
                                translations={s.survey_schema_translations}
                              >
                                {answers[s.unique_id].schema.title}
                              </TranslationsWrapper>
                            </li>
                          ))
                        : "There are no surveys suitable for submission. Please make sure you have filled out all answers and the surveys have not already been submitted"}
                    </ul>
                  </div>
                )}
                <div className="flex justify-evenly pt-6">
                  <div className="">
                    <button
                      disabled={!surveysToSubmit?.length}
                      className="rounded-xl p-1 px-5 text-white text-2xl font-semibold bg-green-600"
                      onClick={() => submit()}
                    >
                      {submitError ? "Retry" : "OK"}
                    </button>
                  </div>
                  <div className="">
                    <button
                      className="rounded-xl p-1 px-5 text-white text-2xl font-semibold bg-gray-400"
                      onClick={() => {
                        setShowConfirm(false)
                        setSubmitError(false)
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      {notSubmittedSurveys && (
        <>
          <div className="inset-0 z-20 flex justify-center items-center fixed m-auto min-h-screen bg-black bg-opacity-50">
            <div className="my-6 px-6 mx-auto md:w-1/2 lg:w-1/4 flex flex-col flex-nowrap justify-center bg-white p-8 rounded-md">
              <>
                <div className="text-gray-700 text-lg font-semibold flex flex-col items-center text-center">
                  You do not have a valid training certificate to submit the
                  following surveys
                </div>
                <div className="text-gray-700 text-lg font-semibold flex flex-col items-center text-center">
                  <ul className="text-gray-500 text-base pt-2">
                    {notSubmittedSurveys.map((survey) => {
                      return (
                        <li key={survey.unique_id}>{survey.schema.title}</li>
                      )
                    })}
                  </ul>
                </div>
              </>
              <div className="flex justify-evenly pt-6">
                <div className="">
                  <button
                    className="rounded-xl p-1 px-5 text-white text-2xl font-semibold bg-green-600"
                    onClick={() => history.push("/assessments")}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

import React, { useEffect, useRef, useState } from "react"
import { useStore } from "react-context-hook"
import { HasAnswerChangedHook } from "../helpers/hasAnswerChangedHook"
import { QuestionStatus } from "../saveQuestion"

export const CalculationQuestion = (props) => {
  const [answer, setAnswer] = useState("")
  const [answers, setAnswers] = useStore("answers", {})
  const [saved, setSaved] = useState(false)
  const [valid, setValid] = useState(true)
  const [errorMessage, setErrorMessage] = useState(null)
  const [saveButtonText, setSaveButtonText] = useState("Save")
  const [saveButtonColour, setSaveButtonColour] = useState("bg-primary")

  const refKey = props.changeAnswerKey

  useEffect(() => {
    getAnswer()
  }, [answers])

  HasAnswerChangedHook(answer, refKey, props.questionRef, props.question.id)

  const getAnswer = () => {
    const thisAnswer = props.getFromAnswers()
    if (thisAnswer) setSaved(true)
    const { unit, decimals, operator, first_question_id, second_question_id } =
      props.question.properties
    let calculatedAnswer = ""

    if (answers.hasOwnProperty(props.params.surveyId)) {
      const questionsCopy = [
        ...answers[props.params.surveyId].schema.sections[
          parseInt(props.params.sectionId.split("?").at(0)) - 1
        ].questions,
      ]
      const firstQuestion = questionsCopy.filter(
        (q) => q.id === first_question_id && q.hasOwnProperty("answer")
      )
      const secondQuestion = questionsCopy.filter(
        (q) => q.id === second_question_id && q.hasOwnProperty("answer")
      )

      if (firstQuestion.length > 0 && secondQuestion.length > 0) {
        const firstAnswer = getAnswerFromQuestion(firstQuestion[0])
        const secondAnswer = getAnswerFromQuestion(secondQuestion[0])

        if (+firstAnswer === +firstAnswer && +secondAnswer === +secondAnswer) {
          calculatedAnswer = eval(`${firstAnswer} ${operator} ${secondAnswer}`)
          if (decimals) calculatedAnswer = calculatedAnswer.toFixed(decimals)
          setValid(true)
          setErrorMessage(null)
        } else {
          setValid(false)
          setErrorMessage("Can't calculate from text, only numbers.")
        }
      }

      if (thisAnswer && calculatedAnswer != thisAnswer) {
        props.removeFromAnswers()
        setSaved(false)
      }

      setAnswer(calculatedAnswer)
    }
  }

  const getAnswerFromQuestion = (question) => {
    switch (question.type) {
      case "text":
        return question.answer
      case "number":
        return question.answer.number
      default:
        return question.answer
    }
  }

  const saveAnswer = () => {
    if (answer === "") {
      setValid(false)
      setErrorMessage("You must enter an answer.")
      setSaveButtonColour("bg-red-600")
      setSaveButtonText("Error!")

      props.removeFromAnswers()
      setSaved(false)
    } else {
      setValid(true)
      setErrorMessage(null)
      setSaveButtonColour("bg-green-600")
      setSaveButtonText("Saved!")

      props.saveToAnswers(answer)
      setSaved(true)
    }

    setTimeout(() => {
      setSaveButtonColour("bg-primary")
      setSaveButtonText("Save")
    }, 1000)
  }

  return (
    <div>
      <div className="mb-2 text-sm text-gray-500">
        ({" "}
        {`Q${props.question.properties.first_question_id} ${props.question.properties.operator} Q${props.question.properties.second_question_id} ) =`}
      </div>
      <input
        type="text"
        className={`w-3/4 text-right outline-none border-2 border-${
          valid ? "gray" : "red"
        }-300 focus:border-2 focus:border-yellow-500 focus:rounded rounded-lg p-2 text-left`}
        value={answer}
        readOnly
        autoComplete="off"
      />{" "}
      &nbsp;
      {props.question.properties.unit}
      <QuestionStatus
        question={props.question}
        answer={answer}
        saveAnswer={saveAnswer}
        saved={saved}
        saveButtonColour={saveButtonColour}
        setSaveButtonColour={setSaveButtonColour}
        saveButtonText={saveButtonText}
        setSaveButtonText={setSaveButtonText}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        comment={props.comment}
        saveComment={props.saveComment}
        saveStandard={props.saveStandard}
        conformance={props.conformance}
        saveConformance={props.saveConformance}
        changeAnswerKey={props.changeAnswerKey}
        questionRef={props.questionRef}
      />
    </div>
  )
}

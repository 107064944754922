import { useEffect } from "react"
import { HasCommentChangedHook } from "./helpers/hasCommentChangedHook"

export const CommentBox = ({
  comment,
  setComment,
  placeholder = "Question comments...",
  title = "Comments:",
  ignoreGlobalChangeMonitoring = false,
  ...props
}) => {
  const handleCommentChange = (c) => {
    setComment(c)
  }

  if (!ignoreGlobalChangeMonitoring) {
    HasCommentChangedHook(
      comment,
      props.changeAnswerKey,
      props.questionRef,
      false
    )
  }

  return (
    <div className="text-left">
      <div className="flex py-2">
        <p className="mr-6">{title}</p>
      </div>
      <textarea
        value={comment}
        onChange={(e) => handleCommentChange(e.target.value)}
        className="w-full border-2 rounded-lg border-gray-300 p-2"
        rows="2"
        placeholder={placeholder}
      />
    </div>
  )
}

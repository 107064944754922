// for general utility functions
import { getSpeciesObject } from "./species"

export { useOnlineStatus } from "./useOnlineStatus"

export const isObjEmpty = (obj) => {
  return Object.keys(obj).length === 0
}

export const isObject = (o) => {
  return o instanceof Object && o.constructor === Object
}

export const evaluateQuestionDisplayConditions2 = (
  question,
  answers,
  survey,
  sectionIdx
) => {
  if (!question.display_conditions) return true
  else if (
    question.display_conditions &&
    (!answers || !answers[survey.unique_id])
  )
    return false

  const surveyUniqueID = survey.unique_id
  const questions =
    answers[surveyUniqueID].schema.sections[sectionIdx].questions
  if (!Array.isArray(question.display_conditions)) return true

  return question.display_conditions?.every((condition) => {
    const answer = questions.find((q) => q.id === condition.question_id).answer
    if (answer === null || typeof answer === "undefined") return false

    if (Array.isArray(answer)) {
      // if array answer check any entries are included in conditional answer
      return answer.some((a) => condition.answer.includes(a))
    } else if (isObject(answer) && isObject(condition.answer)) {
      // if object, get number and unit and compare
      return (
        answer.number == condition.answer.number &&
        answer.unit === condition.answer.unit
      )
    } else if (isObject(answer) && !isObject(condition.answer)) {
      // if object but condition is not, compare numnber to answer
      return answer.number == condition.answer
    } else {
      return answer == condition.answer || answer == condition.answer
    }
  })
}

const generateConditionsExpression = (
  dc_string,
  answers,
  farmSpeciesObjects
) => {
  // dc_string=`({1} contains 'Beef' or {1} contains 'Dairy' or {1} contains 'Lamb' or {1} contains 'Venison') or ({2} = 'Part of a larger farming enterprise that we also own don't 'test' own' or {2} = 'Other')`
  try {
    let match = dc_string
      .replace(/(\s')(.+?)('(?:\sor|\sand|\)|$))/g, (str, ...matches) => {
        return `("${matches[1]}")${matches[2].replace(`'`, ``)}` // Wraps all strings delimited by <'> with parentheses and <"> e.g. 'Beef isn't what you think' --> ("Beef isn't what you think")
      })
      .replaceAll("contains", ".includes")
      .replace(/(['|\)|\]]\s)(or)/g, (str, ...matches) => `${matches[0]}||`) // Replace or/and with || / &&
      .replace(/(['|\)|\]]\s)(and)/g, (str, ...matches) => `${matches[0]}&&`)
      .replaceAll("=", "==")
      .replace(/\{(.*?)\}\s\.includes/g, (str, ...matches) => {
        // Matches array .includes with {question} and subs for [...answer].includes
        return `["${answers
          .find((a) => a.position == matches[0])
          .answer.join('", "')}"].includes`
      })
      .replace(/\{(.*?)\}\s?==/g, (str, ...matches) => {
        // Matches equality comparator with {question} and subs for answer
        return `"${
          answers.find((a) => a.position == matches[0]).answer || null
        }" == `
      })
      .replace(/\[(.*?)\]\s.includes/g, (str, ...matches) => {
        // Matches equality comparator with [species_category] and subs for answer
        let categoryValues = farmSpeciesObjects.map(
          (species) => species[matches[0]]
        )
        return `["${categoryValues.flat().join('", "')}"].includes`
      })
    return match
  } catch (err) {
    return null
  }
}

const runStringExpression = (exp) => {
  try {
    return new Function("return " + exp)()
  } catch (e) {
    return null
  }
}

export const evaluateQuestionDisplayConditions = (
  question,
  answers,
  survey,
  farmSpecies,
  sectionIdx
) => {
  if (!question.display_conditions) {
    return true
  }
  const surveyUniqueID = survey.unique_id
  let answeredQuestions
  if (answers && answers[surveyUniqueID]) {
    answeredQuestions =
      answers[surveyUniqueID].schema.sections[sectionIdx].questions
  } else {
    answeredQuestions = survey.schema.sections[sectionIdx].questions
  }
  const farmSpeciesObjects = farmSpecies
  const conditions_expression = generateConditionsExpression(
    question.display_conditions,
    answeredQuestions,
    farmSpeciesObjects
  )
  if (!conditions_expression) {
    return true
  }
  const result = runStringExpression(conditions_expression)
  if (result === null) {
    return true
  }

  return result ? true : false
}

export const resetNotDisplayedQuestions = (
  question,
  survey,
  sectionIdx,
  answers,
  setAnswers
) => {
  try {
    let answersCopy = answers
    answersCopy[survey.unique_id].schema.sections[sectionIdx].questions.map(
      (q) => {
        if (q.id === question.id) {
          return question
        } else {
          return q
        }
      }
    )
    setAnswers(answersCopy)
    localStorage.setItem("answers", JSON.stringify(answersCopy))
  } catch (error) {
    return
  }
}

export const checkSurveyCompleted = (
  survey,
  surveyAnswers,
  answers,
  species
) => {
  let sectionsCompleted = 0
  if (surveyAnswers && survey) {
    surveyAnswers.schema.sections.forEach((section, sectionIdx) => {
      let questionsCompleted = 0
      section.questions.forEach((q) => {
        if (
          q.hasOwnProperty("answer") ||
          !q.required ||
          !evaluateQuestionDisplayConditions(
            q,
            answers,
            survey,
            species,
            sectionIdx
          ) ||
          q.type === "blank"
        ) {
          questionsCompleted++
        }
      })
      if (questionsCompleted === section.questions.length) {
        sectionsCompleted++
      }
    })
    if (sectionsCompleted === surveyAnswers.schema.sections.length) {
      return true
    }
  }
  return false
}

export const getCurrentSurveyAnswersNumber = (answers, surveyUID) => {
  let number = 0
  Object.keys(answers).forEach((id) => {
    let matches = /(.*)(\/\/)(\d+)/g.exec(id)
    if (
      matches &&
      matches[1] == surveyUID &&
      matches[3] &&
      parseInt(matches[3]) > number
    ) {
      number = parseInt(matches[3])
    }
  })
  return number
}

import React, { useState, useEffect } from "react"
import { FaWindowClose } from "react-icons/fa"

const SlapMark = ({ slapMarksMap, setSlapMarksMap, index }) => {
  const [value, setValue] = useState("")
  const [error, setError] = useState({
    error: false,
    message: "Invalid Slap Mark",
  })

  const validateSlapMark = (value) => {
    let slapMark = String(value)?.toUpperCase()
    let alpha = slapMark?.slice(0, -4)
    let numeric = slapMark?.slice(-4)

    if (
      /^[a-zA-Z]+$/.test(alpha) &&
      numeric?.length === 4 &&
      /^[0-9]+$/.test(numeric)
    ) {
      setError({ ...error, error: false })
      return true
    } else {
      setError({ ...error, error: true })
      return false
    }
  }

  useEffect(() => {
    debugger
    if (!slapMarksMap[0]) {
      setSlapMarksMap((curr) => ({ ...curr, [0]: {} }))
    }
  }, [])

  const handleSubmit = (value) => {
    const isValid = validateSlapMark(value)
    if (isValid) {
      const map = slapMarksMap
      if (!slapMarksMap[index]) {
        slapMarksMap[index] = {}
      }

      const key = Object.keys(slapMarksMap[0]).length
      map[0][key] = value.toUpperCase()

      setSlapMarksMap({ ...map })
      setValue("")
    } else {
      console.log("INVALID")
    }
  }

  const handleDelete = (key) => {
    const map = slapMarksMap
    delete map[index][key]

    setSlapMarksMap({ ...map })
  }

  return (
    <>
      <div className="flex items-center flex-wrap">
        {slapMarksMap[0] &&
          Object.keys(slapMarksMap[0]).map((key) => {
            console.log(slapMarksMap[index][key], slapMarksMap, key, "key")
            return (
              // <div className="flex items-center p-1 rounded-lg mr-2 mb-1">
              <div key={key} className="mb-2">
                {/* <div className="bg-green-200 text-green-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded">
                  {slapMarksMap[index][key]}
                </div>
                <div className="bg-white">
                  <FaWindowClose
                    onClick={() => {
                      handleDelete(key)
                    }}
                    className="text-red-500 cursor-pointer"
                    size={20}
                  />
                </div> */}
                <span
                  id="badge-dismiss-dark"
                  className="inline-flex items-center py-1 px-2 mr-2 text-sm font-medium text-gray-800 bg-gray-100 rounded "
                >
                  {slapMarksMap[index][key]}
                  <button
                    type="button"
                    className="inline-flex items-center p-0.5 ml-2 text-sm text-gray-400 bg-transparent rounded-sm hover:bg-gray-200 hover:text-gray-900 "
                    aria-label="Remove"
                    onClick={() => {
                      handleDelete(key)
                    }}
                  >
                    <svg
                      aria-hidden="true"
                      className="w-3.5 h-3.5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="sr-only">Remove badge</span>
                  </button>
                </span>
              </div>
              // </div>
            )
          })}
      </div>
      {slapMarksMap[index] && (
        <>
          <label className="font-md text-gray-600 ">Slap Mark</label>
          <div className="flex">
            <div className="relative w-full">
              <input
                className="block p-2.5 w-full z-20 text-sm border border-gray-400 rounded"
                style={{ textTransform: "uppercase" }}
                placeholder="Slap Mark"
                type="text"
                value={value}
                name={"business_name"}
                onChange={(e) => setValue(e.target.value)}
                id={"slapmark"}
              />
              <button
                type="button"
                className="absolute top-0 right-0 p-2.5 text-sm font-medium text-white bg-green-600 rounded-r-lg border border-green-700 focus:outline-none cursor-pointer"
                onClick={() => handleSubmit(value)}
              >
                Add Slap Mark
              </button>
            </div>
          </div>
        </>
      )}
      <div className="text-gray-400 ">Example: ABCD1234</div>
      {error.error && (
        <div style={{ color: "#dc3545" }} className="">
          {error.message}
        </div>
      )}
    </>
  )
}

export default SlapMark

import React, { useEffect, useRef, useState } from "react"
import { useStore } from "react-context-hook"

import { CommentBox } from "./commentBox"
import { Conformance } from "./conformance"
import { HasCommentChangedHook } from "./helpers/hasCommentChangedHook"
import NonConformantCommentBox from "./nonConformantCommentBox"

// Extracts the save component in all questions to
// its own component to make modification easier
export const QuestionStatus = (props) => {
  const [conformance, setConformance] = useState(
    props.conformance || { conformant: true, comment: "" }
  )
  const [answers, setAnswers] = useStore("answers", null)

  const [showConformance, setShowConformance] = useState(false)
  const [showConformanceOptions, setShowConformanceOptions] = useState(false)
  const [comment, setComment] = useState(props.comment || "")
  const [nonConformanceComment, setNonConformanceComment] = useState("")
  const [standards, setStandards] = useState(props.question.standards)

  const [displayCommentBox, setDisplayCommentBox] = useState(null)
  const [hasAnswersChangedMap, setHasAnswersChangedMap] = useStore(
    "hasAnswersChangedMap",
    {}
  )

  const refKey = props.changeAnswerKey

  const checkConformanceStatus = () => {
    if (!props.answer) return true
    if (props.answer == props.question.compliant_answer) {
      return true
    }
    return false
  }

  const setErrorMessage = (message, style, buttonText) => {
    props.setErrorMessage("You must leave as comment.")
    props.setSaveButtonColour("bg-red-600")
    props.setSaveButtonText("Error!")
  }

  const manageDeleteHasAnswersChangedMap = () => {
    let temp = hasAnswersChangedMap
    if (temp[refKey]) {
      temp[refKey] = false
      setHasAnswersChangedMap({ ...temp })
    }
    // setHasAnswersChangedMap((curr) => ({ ...curr, [refKey]: false }))
  }

  useEffect(() => {
    if (!props.question.compliant_question) return
    if (props.question.compliant_answer) {
      const compliant = checkConformanceStatus()
      setShowConformance(!compliant)
      setConformance((curr) => ({ ...curr, conformant: compliant }))
    } else {
      setShowConformance(true)
      setShowConformanceOptions(true)
    }
  }, [props.answer])

  const handleSave = () => {
    if (standards && !conformance.conformant) {
      if (nonConformanceComment?.length) {
        manageDeleteHasAnswersChangedMap()
        props.saveAnswer()
        props.saveStandard(standards)
      } else {
        setErrorMessage("You must leave as comment.", "bg-red-600", "Error!")
      }
    } else {
      props.saveStandard(standards, "remove")
      if (props.question.compliant_question) {
        // props.saveConformance(conformance)
        props.saveAnswer()
        manageDeleteHasAnswersChangedMap()

        if (props.question.allow_comment) props.saveComment(comment)
      } else if (!props.question.compliant_question) {
        props.saveAnswer()
        manageDeleteHasAnswersChangedMap()

        if (props.question.allow_comment) {
          props.saveComment(comment)
          manageDeleteHasAnswersChangedMap()
        }
      } else {
        setErrorMessage(
          "You must select a conformance status.",
          "bg-red-600",
          "Error!"
        )
      }
    }
  }

  useEffect(() => {
    getCorrectCommentBox()
  }, [conformance.conformant, props.answer])

  const getCorrectCommentBox = () => {
    if (standards && !conformance.conformant) {
      setDisplayCommentBox("nonConformantCommentBox")
    } else if (
      (props.question.compliant_question &&
        conformance.conformant &&
        Boolean(props.answer)) ||
      props.question.allow_comment
    ) {
      setDisplayCommentBox("commentBox")
    } else {
      setDisplayCommentBox(null)
    }
  }

  // HasCommentChangedHook(comment, refKey, props.questionRef, false)

  const commentBoxType = {
    nonConformantCommentBox: (
      <NonConformantCommentBox
        question={props.question}
        standards={standards}
        setStandards={setStandards}
        title={"Non-conformant Comment"}
        placeholder={"Non-conformant comment..."}
        comment={nonConformanceComment}
        setComment={setNonConformanceComment}
        changeAnswerKey={props.changeAnswerKey}
        questionRef={props.questionRef}
      />
    ),
    commentBox: (
      <CommentBox
        comment={comment}
        setComment={setComment}
        changeAnswerKey={props.changeAnswerKey}
        questionRef={props.questionRef}
      />
    ),
    null: null,
  }

  return (
    <>
      {showConformance && (
        <Conformance
          conformance={conformance}
          setConformance={setConformance}
          conformanceOptions={showConformanceOptions}
          changeAnswerKey={props.changeAnswerKey}
          questionRef={props.questionRef}
        />
      )}
      {/* {displayCommentBox} */}
      {commentBoxType[displayCommentBox]}

      <div className="mt-2 text-red-500">{props.errorMessage}</div>
      <div className="mt-4">
        <button
          type="button"
          className={`transition duration-150 rounded-lg p-2 w-24 ${props.saveButtonColour} text-white font-semibold`}
          onClick={handleSave}
        >
          {props.saveButtonText}
        </button>
      </div>
      {/* <div className="mt-6 font-semibold">
        <div className="text-2xl">
          {saved &&
            <span className="text-green-600"><FaRegCheckSquare className="inline" /></span>
          }
          {!saved &&
            <span className="text-gray-400"><FaRegSquare className="inline" /></span>
          }
        </div>
      </div> */}
    </>
  )
}

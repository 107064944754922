import { useState } from "react"
import { useStore } from "react-context-hook"
import { Link, useHistory } from "react-router-dom"
import { formatBytes } from "../../utils/fileFormat"
import Standards from "../components/questions/standards"
import TranslationWrapper from "../utils/translationWrapper"
import { ConformanceReview } from "./conformanceReview"

export const NonConformantQuestion = ({
  survey,
  section,
  question,
  questionNumber = null,
  setHasAnswersChangedMap,
  hasAnswersChangedMap,
  refList,
  setRefList,
}) => {
  const [answers, setAnswers] = useStore("answers", null)
  const [saved, setSaved] = useState(false)
  const [collapsed, setCollapsed] = useState(false)

  const history = useHistory()

  const getChangedAnswerKey = (question) => {
    let id = String(question.description).substring(0, 10).replaceAll(" ", "_")
    return `${question.id}${question.position}${question.type}${id}`
  }

  const key = getChangedAnswerKey(question)

  const saveConformance = (conformance) => {
    manageDeleteHasAnswersChangedMap()
    const copyOfAnswers = answers ? { ...answers } : {}

    if (copyOfAnswers[survey.unique_id]) {
      copyOfAnswers[survey.unique_id].schema.sections[section].questions.find(
        (q) => q.id === question.id
      ).actions = conformance
    }

    localStorage.setItem("answers", JSON.stringify(copyOfAnswers))
    setAnswers(copyOfAnswers)
    setSaved(true)
    setCollapsed(false)
  }

  const manageDeleteHasAnswersChangedMap = () => {
    const key = getChangedAnswerKey(question)
    // setHasAnswersChangedMap((curr) => ({ ...curr, [key]: false }))

    let temp = hasAnswersChangedMap
    if (temp[key]) {
      temp[key] = false
      setHasAnswersChangedMap({ ...temp })
    }
  }

  const handleQuestionClick = () => {
    if (saved) {
      setCollapsed((c) => !c)
    }
  }
  return (
    <div
      ref={refList[key]}
      className={`mt-3 p-3 border-b-2 ${
        question.position == 1 && "border-t-2"
      } ${
        saved
          ? "border-green-500 md:border-2 bg-green-50 md:bg-white"
          : "border-gray-500 md:border"
      } text-center md:rounded-lg md:mb-4`}
    >
      <div
        onClick={handleQuestionClick}
        className={`font-semibold text-gray-400 ${saved && "cursor-pointer"}`}
      >
        Question {questionNumber}
        <div className={`font-normal text-primary underline pt-2`}>
          <Link
            to={{
              pathname: `${
                // answers[survey.unique_id]["schema"]["sections"]?.location
                question?.location
              }?ref=${key}`,
              state: { prevPath: history.location.pathname },
            }}
          >
            {`Change answer`}
          </Link>
        </div>
      </div>
      {question.standards && (
        <Standards question={question} standards={question.standards} />
      )}

      <div
        onClick={handleQuestionClick}
        className={`font-semibold  text-lg text-gray-800 ${
          saved && "cursor-pointer"
        }`}
      >
        <TranslationWrapper translations={survey.survey_schema_translations}>
          {question.question}
        </TranslationWrapper>
        {!question.required && " (Optional)"}
      </div>
      {
        <div className={!saved || collapsed ? "" : "hidden"}>
          {question.description && (
            <div className="text-gray-500 text-sm">
              <TranslationWrapper
                translations={survey.survey_schema_translations}
              >
                {question.description}
              </TranslationWrapper>
            </div>
          )}
          <p className="capitalize">{question.answer}</p>
          <ConformanceReview
            conformance={question.actions}
            saveConformance={saveConformance}
            saved={saved}
            setSaved={setSaved}
            setHasAnswersChangedMap={setHasAnswersChangedMap}
            hasAnswersChangedMap={hasAnswersChangedMap}
            question={question}
            refList={refList}
            setRefList={setRefList}
            getChangedAnswerKey={getChangedAnswerKey}
          />
          {Array.isArray(question.files) &&
            question.files?.map((f, idx) => (
              <div
                key={idx}
                className="rounded-xl p-2 my-1 border-2 border-green-600 flex align-middle"
              >
                <p className="my-auto button break-all mr-2">
                  {f.name} — ({formatBytes(f.size)})
                </p>
                {/* <button onClick={() => handleFileDelete(f)} className="rounded-md bg-red-600 active:bg-red-800 p-2 ml-auto mr-0">
              <FaTrash color="white"/>
            </button> */}
              </div>
            ))}
        </div>
      }
    </div>
  )
}

import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useStore } from "react-context-hook"
import { Header } from "../components/header"
import { NavBar } from "../components/navbar"
import { AiOutlineClose } from "react-icons/ai"
import { FaAngleRight } from "react-icons/fa"
import { languages } from "../../config/languages"

export const Home = () => {
  const [showAccountModal, setShowAccountModal] = useStore(
    "showAccountModal",
    false
  )
  const [language, setLanguage] = useStore("language", "en")
  const [translationIds, setTranslationIds] = useStore("translationIds")
  const [account, setAccount] = useStore("account", null)
  const [assessments, setAssessments] = useStore("assessments", [])
  const [captureGroups, setCaptureGroups] = useStore("captureGroups", [])
  const [sortedLanguages, setSortedLanguages] = useState(null)

  const [appConfig, setAppConfig] = useStore("appConfig", null)

  const history = useHistory()

  useEffect(() => {
    const _sortedLanguages = sortLanguages(getSpecificTenantLanguages())
    setSortedLanguages(_sortedLanguages)
  }, [])

  const getSpecificTenantLanguages = () => {
    const lang = {}
    translationIds.forEach((id) => {
      lang[id] = languages[id]
    })
    return lang
  }

  const sortLanguages = (languages) => {
    return Object.keys(languages).sort((a, b) => {
      let aName = languages[a].name.toUpperCase()
      let bName = languages[b].name.toUpperCase()
      if (aName < bName) {
        return -1
      }
      if (aName > bName) {
        return 1
      }
      return 0
    })
  }

  const submitLogout = () => {
    fetch(`${process.env.REACT_APP_API_HOST}/auth/logout`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      credentials: "include",
    })
      .then((res) => res.json())
      .finally(() => {
        history.push("/login")
      })
  }

  const updatePreferredLanguage = (newLanguage) => {
    localStorage.setItem("language", newLanguage)
    setLanguage(newLanguage)
  }

  return (
    <>
      {/* <Header /> */}
      <NavBar
        text={`Hello ${account.firstname}`}
        iconLeft="refresh"
        iconRight="account"
      />
      {/* <div className="text-center text-5xl font-bold text-gray-600 pt-10 pb-15">
        <p>
          You have<br />
          <span className="text-primary text-9xl">{assessments.length}</span><br />
          assessments<br />
          pending
          and {captureGroups.length} CGs
        </p>
      </div> */}
      <div className="md:pt-10 pb-15 flex flex-col md:gap-2">
        {!appConfig || appConfig?.enableAssessments ? (
          <div className="flex flex-row w-full md:w-1/2 xl:w-1/3 mx-auto text-3xl font-bold text-gray-600 border-gray-500 border-b-2 md:border-2 md:rounded-xl">
            <p className="text-primary text-6xl p-2 px-3">
              {assessments.length}
            </p>
            <p className="align-middle my-auto">Assessments Pending</p>
            <button
              className="md:rounded-lg md:rounded-l-none ml-auto p-1 px-6 text-white text-3xl font-semibold bg-primary"
              onClick={(e) => history.push("/assessments")}
            >
              Start
            </button>
          </div>
        ) : null}
        {!appConfig || appConfig?.enableCaptureGroups ? (
          <div className="flex flex-row w-full md:w-1/2 xl:w-1/3 mx-auto text-3xl font-bold text-gray-600 border-gray-500 border-b-2 md:border-2 md:rounded-xl">
            <p className="text-primary text-6xl p-2 px-3">
              {captureGroups.length}
            </p>
            <p className="align-middle my-auto">Farms Active</p>
            <button
              className="md:rounded-lg md:rounded-l-none z-0 ml-auto p-1 px-6 text-white text-3xl font-semibold bg-primary"
              onClick={(e) => history.push("/capture-groups")}
            >
              Start
            </button>
          </div>
        ) : null}
      </div>
      {/* <div className="text-center pt-12">
        <button className="rounded-xl p-1 px-12 pr-8 text-white text-3xl font-semibold bg-primary" onClick={(e) => history.push('/assessments')}>
          Start<FaAngleRight className="inline text-4xl" />
        </button>
      </div> */}
      {showAccountModal && (
        <div className="fixed w-screen top-0 z-30 left-0 h-screen mx-auto bg-gray-300 bg-opacity-70 grid place-items-center">
          <div className="md:w-1/2  xl:w-1/4 bg-white border-2 rounded border-black p-5">
            <div className="text-right text-primary text-4xl">
              <AiOutlineClose
                className="inline mr-1 md:cursor-pointer"
                onClick={(e) => setShowAccountModal(false)}
              />
            </div>
            <div className="mt-5 text-2xl text-center">
              You are logged in to
              <br />
              <span className="font-semibold text-black text-lg">
                {account.tenant.name}
              </span>
              <br />
              <span className="text-gray-500">as</span>
              <br />
              <span className="font-semibold text-black text-lg">
                {account.email}
              </span>
              <br />
            </div>
            <div className="mt-5 text-center">
              <p>Prefered language</p>
              <select
                className="mt-2 w-full border-2 rounded px-2 lg:w-3/4"
                defaultValue={language}
                onChange={(e) => updatePreferredLanguage(e.target.value)}
              >
                {sortedLanguages.map((code, i) => {
                  return (
                    <option key={i} value={code}>
                      {languages[code].name}
                    </option>
                  )
                })}
              </select>
            </div>
            <div className="flex gap-2 flex-col md:flex-row justify-center mt-10 text-center">
              <button
                className="rounded-xl p-1 px-7  text-white text-2xl font-semibold bg-primary cursor-pointer"
                onClick={() => history.push("/login/select-profile")}
              >
                Switch Account
                {/* <FaAngleRight className="inline text-4xl" /> */}
              </button>
              <button
                className="rounded-xl p-1 px-7  text-white text-2xl font-semibold bg-primary"
                onClick={submitLogout}
              >
                Log out
                {/* <FaAngleRight className="inline text-4xl" /> */}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

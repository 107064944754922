import { useEffect } from "react"
import { HasAnswerChangedHook } from "../helpers/hasAnswerChangedHook"

export const Conformance = ({
  conformance,
  setConformance,
  conformanceOptions,

  changeAnswerKey,
  questionRef,
}) => {
  const refKey = changeAnswerKey

  const handleCommentChange = (comment) => {
    setConformance((curr) => ({ ...curr, comment }))
  }

  const handleStatusChange = (status) => {
    setConformance((curr) => ({ ...curr, conformant: JSON.parse(status) }))
  }

  useEffect(() => {
    if (JSON.parse(conformance.conformant) && conformance.comment) {
      setConformance({ conformant: true })
    }
  }, [conformance])

  HasAnswerChangedHook(conformance.conformant, refKey, questionRef)

  return (
    <div className="text-left">
      {conformanceOptions ? (
        <form
          className="flex py-2"
          onChange={(e) => {
            handleStatusChange(e.target.value)
          }}
        >
          <p className="mr-6">Conformant:</p>
          <label className="m-auto">
            <input
              type="radio"
              value={true}
              name="conformant"
              defaultChecked={
                conformance.hasOwnProperty("conformant")
                  ? JSON.parse(conformance.conformant)
                  : false
              }
            />{" "}
            Yes
          </label>
          <label className="m-auto">
            <input
              type="radio"
              value={false}
              name="conformant"
              defaultChecked={
                conformance.hasOwnProperty("conformant")
                  ? !JSON.parse(conformance.conformant)
                  : false
              }
            />{" "}
            No
          </label>
        </form>
      ) : null}
      {/* {!JSON.parse(conformance.conformant) ? (
        <>
          <div className="flex py-2">
            <p className="mr-6">Non-conformance comment:</p>
          </div>
          <textarea
            value={conformance.comment || ""}
            onChange={(e) => handleCommentChange(e.target.value)}
            className="w-full border-2 rounded-lg border-gray-300 p-2"
            rows="4"
            placeholder="Comments..."
          />
        </>
      ) : null} */}
    </div>
  )
}

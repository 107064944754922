import React, { useState, useEffect, useCallback } from "react"
import { useStore } from "react-context-hook"

export function HasCommentChangedHook(
  comment,
  key,
  questionRef,
  getSavedComment
) {
  const [answers, setAnswers] = useStore("answers", null)
  const [copyOfComment, setCopyOfComment] = useState(null)
  const [refList, setRefList] = useStore("refList", {})

  const [hasAnswersChangedMap, setHasAnswersChangedMap] = useStore(
    "hasAnswersChangedMap",
    {}
  )
  const [hasAnswersChanged, setHasAnswersChanged] = useStore(
    "hasAnswersChanged",
    false
  )
  const [update, setUpdate] = useState(false)

  const hasAnswersChangedHelper = useCallback(() => {
    if (hasAnswersChangedMap) {
      for (const [key, value] of Object.entries(hasAnswersChangedMap)) {
        if (value.answer || value.comment) {
          setHasAnswersChanged(true)
          break
        }
        setHasAnswersChanged(false)
      }
    }
  }, [hasAnswersChangedMap])

  useEffect(() => {
    const timeOutId = setTimeout(() => setUpdate(!update), 300)
    return () => clearTimeout(timeOutId)
  }, [comment])

  useEffect(() => {
    if (typeof getSavedComment === "function") {
      setCopyOfComment(getSavedComment())
    } else {
      let copy = comment || ""
      setCopyOfComment(copy)
    }
  }, [])

  useEffect(() => {
    if (typeof getSavedComment === "function") {
      setCopyOfComment(getSavedComment())
    } else {
      let copy = comment || ""
      setCopyOfComment(copy)
    }
  }, [answers])

  useEffect(() => {
    if ((copyOfComment || copyOfComment === "") && comment !== copyOfComment) {
      setHasAnswersChangedMap({
        ...hasAnswersChangedMap,
        [key]: {
          ...hasAnswersChangedMap[key],
          comment: true,
        },
      })
      hasAnswersChangedHelper()
    } else if (comment === copyOfComment) {
      setHasAnswersChangedMap({
        ...hasAnswersChangedMap,
        [key]: {
          ...hasAnswersChangedMap[key],
          comment: false,
        },
      })
      hasAnswersChangedHelper()
    }
    setRefList({ ...refList, [key]: questionRef })
  }, [update, copyOfComment])
}

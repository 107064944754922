import React from "react"

export const ModalWrapper = ({ children }) => {
  return (
    <div className="inset-0 z-20 flex justify-center items-center fixed m-auto min-h-screen bg-black bg-opacity-50">
      <div className="my-6 px-6 mx-auto md:w-1/2 lg:w-2/4 flex flex-col flex-nowrap justify-center bg-white p-8 py-12 rounded-md max-w-xl">
        {children}
      </div>
    </div>
  )
}
